import { useEffect } from 'react';
import { alarmSounds } from '../assets';
import { useLog } from './useLog';

const notificationAudio = new Audio(alarmSounds.find((_) => _.name === 'News')!.src);
notificationAudio.load();
notificationAudio.volume = 0.5;

export const useNotification = () => {
  const log = useLog();

  const isSupportedNotification = () => {
    return 'Notification' in window;
  };

  const askNotificationPermission = () => {
    function checkNotificationPromise() {
      try {
        Notification.requestPermission().then();
      } catch (e) {
        return false;
      }

      return true;
    }

    if (!isSupportedNotification()) {
      console.error('This browser does not support notifications.');
    } else if (checkNotificationPromise()) {
      Notification.requestPermission().then();
    } else {
      Notification.requestPermission();
    }
  };

  const showNotification = (title: string, options?: NotificationOptions) => {
    if (isSupportedNotification() && Notification.permission !== 'denied') {
      new window.Notification(title, options);
    } else {
      log.info(title, { anchorOrigin: { horizontal: 'right', vertical: 'top' } });
    }
    notificationAudio.play();
  };

  useEffect(() => {
    askNotificationPermission();
  }, []);

  return { isSupportedNotification, askNotificationPermission, showNotification } as const;
};
