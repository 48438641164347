import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AutoStartType, NotificationType, SliceType } from '../../types';
import { defaultSetting } from '../../utils/utils';
import { RootState } from '../store';

export interface SettingState {
  [AutoStartType.autoStartFocus]: boolean;
  [AutoStartType.autoStartBreak]: boolean;
  notificationType: NotificationType;
  notificationMinutes: number;
  darkMode: boolean;
  longBreakInterval: number;
}

const initialState = {
  [AutoStartType.autoStartFocus]: defaultSetting.autostart_pomodoro,
  [AutoStartType.autoStartBreak]: defaultSetting.autostart_break,
  notificationType: defaultSetting.notification_type,
  notificationMinutes: defaultSetting.notification_minutes,
  darkMode: defaultSetting.dark_mode,
  longBreakInterval: defaultSetting.long_break_interval,
} as SettingState;

export const settingSlice = createSlice({
  name: SliceType.setting,
  initialState,
  reducers: {
    setAutoStartBreak: (state, action: PayloadAction<boolean>) => {
      state.autoStartBreak = action.payload;
    },
    setAutoStartFocus: (state, action: PayloadAction<boolean>) => {
      state.autoStartFocus = action.payload;
    },
    setNotificationType: (state, action: PayloadAction<NotificationType>) => {
      state.notificationType = action.payload;
    },
    setNotificationMinutes: (state, action: PayloadAction<number>) => {
      state.notificationMinutes = action.payload;
    },
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
    },
    setLongBreakInterval: (state, action: PayloadAction<number>) => {
      state.longBreakInterval = action.payload;
    },
    updateSettingData: (state, action: PayloadAction<SettingState>) => {
      state.autoStartBreak = action.payload.autoStartBreak;
      state.autoStartFocus = action.payload.autoStartFocus;
      state.notificationType = action.payload.notificationType;
      state.notificationMinutes = action.payload.notificationMinutes;
      state.darkMode = action.payload.darkMode;
      state.longBreakInterval = action.payload.longBreakInterval;
    },
  },
});

export const {
  setAutoStartBreak,
  setAutoStartFocus,
  setNotificationType,
  setNotificationMinutes,
  setDarkMode,
  setLongBreakInterval,
  updateSettingData,
} = settingSlice.actions;

export const selectSetting = createSelector(
  (state: RootState) => state.setting,
  (settingState) => settingState,
);

export default settingSlice.reducer;
