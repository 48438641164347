import { CSSProperties, PropsWithChildren } from 'react';
import { FOCUS_COLOR, LONG_BREAK_COLOR, SHORT_BREAK_COLOR } from '../../constants/Colors';
import { useAppSelector } from '../../redux/store';
import { selectTimerSetting } from '../../redux/timer/timerSlice';
import { SessionType } from '../../types';
import { Grid, Typography, styled } from '@mui/material';
import { clsx } from 'clsx';
import { PartnersLogoMarquee } from './PartnersLogoMarquee';
import { CertifiedIcon } from '../../assets';

export const Partners = () => {
  const { sessionType } = useAppSelector(selectTimerSetting);

  return (
    <Background session={sessionType}>
      <Wrapper>
        <TextWrapper>
          <Grid container justifyContent={'center'}>
            <Grid item lg={12} md={8} sm={4} xs={4}>
              <Title color={'white'}>What news companies are saying about Focus Keeper</Title>
              <Description></Description>
            </Grid>
          </Grid>
        </TextWrapper>
        <StyledPartnersLogoMarquee />
      </Wrapper>
    </Background>
  );
};

interface BackgroundProps extends PropsWithChildren {
  className?: string;
  session: SessionType;
}

const Background = styled('section')<BackgroundProps>`
  ${({ session }) => session === SessionType.focus && `background-color: ${FOCUS_COLOR};`};
  ${({ session }) => session === SessionType.shortBreak && `background-color: ${SHORT_BREAK_COLOR};`};
  ${({ session }) => session === SessionType.longBreak && `background-color: ${LONG_BREAK_COLOR};`};
  transition: var(--background-transition-duration) background-color;
`;

const Wrapper = styled('div')`
  margin: auto;
  padding: 60px 0 80px;
  overflow: hidden;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 60px 0px 80px;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 60px 0px 80px;
  }
`;

const TextWrapper = styled('div')`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 0 40px;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0;
  }
`;

const Title = styled(Typography)`
  font-family: Pretendard;
  font-size: 32px;
  font-weight: 600;
  line-height: 41.6px;
  text-align: center;
  margin-bottom: 16px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0 20px;
    font-family: Pretendard;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 41.6px */
  }
`;

interface DescriptionProps {
  className?: string;
  style?: CSSProperties;
}

const Description = styled(({ className, style }: DescriptionProps) => {
  return (
    <Typography color={'white'} component={'p'} className={clsx(className, 'Description')} style={style}>
      and over 450 News Sites <CertifiedSymbol></CertifiedSymbol>
    </Typography>
  );
})`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Pretendard;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
`;

interface CertifiedIconProps {
  className?: string;
  style?: CSSProperties;
}

const CertifiedSymbol = styled(({ className, style }: CertifiedIconProps) => {
  return <img className={className} style={style} width={12} src={CertifiedIcon} alt='Certified'></img>;
})`
  margin: 0 4px;
`;

interface StyledPartnersLogoMarqueeProps {
  className?: string;
  style?: CSSProperties;
}

const StyledPartnersLogoMarquee = styled(({ className, style }: StyledPartnersLogoMarqueeProps) => {
  return <PartnersLogoMarquee className={className} style={style}></PartnersLogoMarquee>;
})<StyledPartnersLogoMarqueeProps>`
  margin-top: 52px;
`;
