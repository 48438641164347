import { styled, Typography } from '@mui/material';
import { CSSProperties } from 'react';
import InlineSVG from 'react-inlinesvg';
import { AppleWhiteIcon } from '../../assets';

interface DescriptionProps {
  className?: string;
  style?: CSSProperties;
}

export const Description = ({ className, style }: DescriptionProps) => {
  return (
    <Wrapper>
      <InlineSVG src={AppleWhiteIcon} width={32} height={32}></InlineSVG>
      <StoreName>App Store</StoreName>
      <ReviewCount>100+ reviews</ReviewCount>
    </Wrapper>
  );
};

const Wrapper = styled('p')`
  display: flex;
  align-items: center;
`;

const StoreName = styled(Typography)`
  margin-left: 8px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
  color: white;
`;

const ReviewCount = styled(Typography)`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  color: #0000008a;
  margin-left: 8px;
`;
