import { Action } from 'redux';
import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { SliceType } from '../types';
import timerReducer from './timer/timerSlice';
import audioReducer from './audio/audioSlice';
import settingReducer from './setting/settingSlice';
import themeReducer from './theme/themeSlice';
import chartReducer from './charts/chartsSlice';
import userReducer from './user/userSlice';

export const store = configureStore({
  reducer: {
    [SliceType.timer]: timerReducer,
    [SliceType.audio]: audioReducer,
    [SliceType.setting]: settingReducer,
    [SliceType.theme]: themeReducer,
    [SliceType.chart]: chartReducer,
    [SliceType.user]: userReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<any>>;
export type AppThunkConfig = { state: RootState };

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
