import { logEvent } from '@amplitude/analytics-browser';
import { Box } from '@mui/material';
import { terminate } from '@shopify/web-worker';
import { useContext, useEffect, useRef } from 'react';
import { batchLogEvent } from '../../api/api';
import { AudioContext } from '../../context/AudioContext';
import { selectTodayChart } from '../../redux/charts/chartsSlice';
import { selectSetting } from '../../redux/setting/settingSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { selectTimerInfos, selectTimerSetting, setSessionCount, setSessionType } from '../../redux/timer/timerSlice';
import { TaxonomyEventActions, TaxonomyEventNames } from '../../types';
import { combine } from '../../utils/utils';
import { timerWorker, worker } from '../../workers';
import { Timer } from '../Timer/Timer';
import { LeavingScreenState } from './CountdownTimer.interface';

export const CountdownTimer = () => {
  const dispatch = useAppDispatch();
  const timerSetting = useAppSelector(selectTimerSetting);
  const todayChart = useAppSelector(selectTodayChart);
  const timerInfos = useAppSelector(selectTimerInfos);
  const notificationType = useAppSelector(selectSetting).notificationType;
  const notificationMinutes = useAppSelector(selectSetting).notificationMinutes;
  const longBreakInterval = useAppSelector(selectSetting).longBreakInterval;
  const audioState = useContext(AudioContext);
  const leavingScreenState = useRef<LeavingScreenState | null>(null);
  const { tickingAudioState } = audioState;

  useEffect(() => {
    const visibilityHandler = () => {
      if (timerSetting.ticking === false) return;

      if (document.hidden) {
        leavingScreenState.current = {
          hiddenDate: new Date(),
          longBreakInterval: longBreakInterval,
          timerSetting: { ...timerSetting },
          timerInfos: { ...timerInfos },
          todayChart: { ...todayChart },
        };
      } else {
        const _leavingScreenState = leavingScreenState.current;

        if (_leavingScreenState === null) return;

        worker.worker(_leavingScreenState).then(async (result) => {
          const { completeGoalDate, completeRoundDates } = result;

          if (completeRoundDates.length) {
            const eventType = combine([TaxonomyEventActions.complete, TaxonomyEventNames.round]);
            const events = completeRoundDates.map((date) => {
              return { event_type: eventType, time: date.getTime() };
            });

            batchLogEvent(events);
          }

          if (completeGoalDate) {
            const eventType = combine([TaxonomyEventActions.complete, TaxonomyEventNames.today_goal]);

            logEvent(eventType, undefined, { time: completeGoalDate.getTime() });
          }

          timerWorker.stopTimer().then(() => {
            const leftSeconds =
              timerInfos[result.leavingScreenState.timerSetting.sessionType].second - result.leftSeconds;

            dispatch(setSessionType(result.leavingScreenState.timerSetting.sessionType));
            dispatch(setSessionCount(result.leavingScreenState.timerSetting.sessionCount));
            timerWorker.playTimer(leftSeconds, { notificationType, notificationMinutes });
            leavingScreenState.current = null;
          });
        });
      }
    };

    document.addEventListener('visibilitychange', visibilityHandler);
    return () => document.removeEventListener('visibilitychange', visibilityHandler);
  }, [
    timerSetting,
    timerInfos,
    tickingAudioState,
    dispatch,
    longBreakInterval,
    todayChart,
    notificationType,
    notificationMinutes,
  ]);

  useEffect(() => {
    return () => {
      terminate(timerWorker);
    };
  }, []);

  return (
    <Box sx={{ width: 'fit-content' }}>
      <Timer />
    </Box>
  );
};
