import { PropsWithChildren, ReactNode } from 'react';
import { useAppSelector } from '../../redux/store';
import { selectUserId } from '../../redux/user/userSlice';

interface AuthorizedProps extends PropsWithChildren {
  fallback?: ReactNode;
}

export const Authorized = ({ children, fallback }: AuthorizedProps) => {
  const user = useAppSelector(selectUserId);
  const isAuthorized = Boolean(user);

  return <>{isAuthorized ? children : fallback}</>;
};
