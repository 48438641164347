import { Box, CircularProgress } from '@mui/material';
import { Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Dialog } from '../components/Dialog/Dialog';
import { Main } from '../components/Main/Main';
import { ModalType, Paths } from '../types';
import {
  LazyCharts,
  LazyConfirmEmail,
  LazyForgotPassword,
  LazyLogin,
  LazyProfile,
  LazyRegister,
  LazySetPassword,
  LazySetting,
} from '../utils/lazyLoad';

const lazyLoadRoutes = [
  { path: ModalType.forgotPassword, child: <LazyForgotPassword /> },
  { path: ModalType.profile, child: <LazyProfile /> },
  { path: ModalType.options, child: <LazySetting /> },
  { path: ModalType.login, child: <LazyLogin /> },
  { path: ModalType.charts, child: <LazyCharts /> },
  { path: ModalType.register, child: <LazyRegister /> },
];

const FallbackWrap = () => {
  return (
    <Box margin={'50px auto'} width='fit-content' textAlign='center'>
      <p>Loading ...</p>
      <CircularProgress sx={{ margin: '20px' }} />
    </Box>
  );
};

const Router = () => {
  return (
    <Routes>
      <Route
        path={Paths.confirmEmail}
        element={
          <Suspense fallback={<FallbackWrap />}>
            <LazyConfirmEmail />
          </Suspense>
        }
      />
      <Route
        path={Paths.setPassword}
        element={
          <Suspense fallback={<FallbackWrap />}>
            <LazySetPassword />
          </Suspense>
        }
      />
      <Route path={Paths.home} element={<Main />}>
        <Route path={Paths.modal} element={<Outlet />}>
          {lazyLoadRoutes.map(({ path, child }) => {
            // ? errorElement, hasErrorBoundary
            return (
              <Route
                key={path}
                path={path}
                element={
                  <Suspense
                    fallback={
                      <Dialog open={true} title={path}>
                        <CircularProgress sx={{ margin: '20px' }} />
                      </Dialog>
                    }
                  >
                    {child}
                  </Suspense>
                }
              />
            );
          })}
          <Route path='modal' element={<Navigate to='/' replace />} />
        </Route>
      </Route>
      <Route path='/*' element={<Navigate to='/' replace />} />
    </Routes>
  );
};

export default Router;
