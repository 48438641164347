export const Reviews = [
  {
    title: 'Great app!',
    content:
      'When I’m noticing myself procrastinating I open the app and know that I just have to stick to the schedule and my productivity will increase. Great app! Easy to use!',
    author: 'Jins',
  },
  {
    title: 'Super Productive',
    content:
      'Productivity that is! Just follow the time prompts, and stay on task during your focus time, productivity and efficiency will be increased by 1000%',
    author: 'Aarav Patel',
  },
  {
    title: 'Very helpful',
    content:
      'This app helps me to stay focused and not divert or get distracted on other things. Has helped me a tons. Love the alarm is very small and shuttle but will catch your attention',
    author: 'Sofia',
  },
  {
    title: 'Future of my productivity',
    content:
      'This app is EVERYTHING and more for all I’ve desired as a remedy for my struggles with focus. To the creators, thank you 😊',
    author: 'Kbqn',
  },
  {
    title: 'Great for tracking focus sessions pomo technique',
    content:
      'Great for focus and pomodora technique,only problem is I have is sometimes i forget to pause or end it and it keeps going and going. Just be mindful to stop when finished',
    author: 'Ivan',
  },
  {
    title: 'Love this app!',
    content:
      'I used to feel overwhelmed with tasks, and this app helped get me out of it. and into productivity, whether it be work, hobbies, or housework.',
    author: 'Luca.R',
  },
  {
    title: '5-Star App',
    content: 'This app has helped me as a grad student to stay focused on a task and get a better sense of time!!',
    author: 'juliananaples',
  },
  {
    title: 'Love this app!',
    content:
      'I used to feel overwhelmed with tasks, and this app helped get me out of it. and into productivity, whether it be work, hobbies, or housework.',
    author: 'Jojizzal',
  },
  {
    title: 'very helpful to track creative work time',
    content: 'great for tracking time committed to tasks, and noticing when it’s time to take a break to fresh',
    author: 'Whizzle69',
  },
] as const;
