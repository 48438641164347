import { createContext, ReactNode } from 'react';
import { noneHowl } from '../assets';
import { useAudio } from '../hooks/useAudio';

type UseState = ReturnType<typeof useAudio>;

export interface AudioContextState {
  tickingAudioState: UseState;
  alarmAudioState: UseState;
}

const defaultAudioState: UseState = {
  id: 0,
  audio: noneHowl,
  loading: false,
  setId: (id) => {},
  setAudio: (howl) => {},
  setLoading: (loading) => {},
  changeId: (id) => {},
  changeAudio: (howl) => {},
  changeLoading: (loading) => {},
};

export const AudioContextDefaultValue: AudioContextState = {
  tickingAudioState: defaultAudioState,
  alarmAudioState: defaultAudioState,
};

export const AudioContext = createContext<AudioContextState>(AudioContextDefaultValue);

export interface PropsType {
  children?: ReactNode;
}

export const AudioContextProvider = (props: PropsType) => {
  const alarmAudioState = useAudio();
  const tickingAudioState = useAudio();

  return (
    <AudioContext.Provider
      value={{
        alarmAudioState: alarmAudioState,
        tickingAudioState: tickingAudioState,
      }}
    >
      {props.children}
    </AudioContext.Provider>
  );
};
