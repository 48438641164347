import { styled, Typography } from '@mui/material';
import { CSSProperties, useCallback } from 'react';
import { clsx } from 'clsx';
import { FOCUS_COLOR, INTERVAL_COLOR, LONG_BREAK_COLOR, SHORT_BREAK_COLOR } from '../../constants/Colors';
import InlineSVG from 'react-inlinesvg';
import { FocusTimeIcon, IntervalIcon, LongTimeIcon, ShortTimeIcon } from '../../assets';
import { GuideStep } from '../../types/randing';

interface TimerStepProps {
  className?: string;
  style?: CSSProperties;
  type: GuideStep;
}

export const TimerStep = styled(({ className, style, type }: TimerStepProps) => {
  return (
    <div className={clsx('TimerStep', className)} style={style}>
      <Icon type={type}></Icon>
      <StepName type={type}></StepName>
    </div>
  );
})`
  display: flex;
  column-gap: 8px;
  align-items: center;
  padding: 6px 12px;
  border-radius: 28px;
  background-color: ${({ type }) => (type === GuideStep.focus ? `${FOCUS_COLOR};` : undefined)};
  background-color: ${({ type }) => (type === GuideStep.shortBreak ? `${SHORT_BREAK_COLOR};` : undefined)};
  background-color: ${({ type }) => (type === GuideStep.longBreak ? `${LONG_BREAK_COLOR};` : undefined)};
  background-color: ${({ type }) => (type === GuideStep.interval ? `${INTERVAL_COLOR};` : undefined)};
`;

interface IconProps {
  className?: string;
  style?: CSSProperties;
  type: GuideStep;
}

const Icon = styled(({ className, style, type }: IconProps) => {
  let icon = FocusTimeIcon;
  if (type === GuideStep.shortBreak) icon = ShortTimeIcon;
  if (type === GuideStep.longBreak) icon = LongTimeIcon;
  if (type === GuideStep.interval) icon = IntervalIcon;

  const preProcessor = useCallback((svg: string) => {
    const tree = new DOMParser().parseFromString(svg, 'image/svg+xml');
    const paths = tree.getElementsByTagName('path');

    for (let i = 0; i < paths.length; i += 1) {
      const path = paths.item(i);
      path?.setAttribute('fill', '#FFF');
    }

    return tree.documentElement.outerHTML;
  }, []);

  return (
    <InlineSVG
      className={className}
      style={style}
      width={24}
      height={24}
      src={icon}
      preProcessor={preProcessor}
    ></InlineSVG>
  );
})`
  width: 24px;
  height: 24px;
`;

interface StepNameProps {
  className?: string;
  style?: CSSProperties;
  type: GuideStep;
}

const StepName = styled(({ className, style, type }: StepNameProps) => {
  let name = 'Focus Time';
  if (type === GuideStep.shortBreak) name = 'Short break';
  if (type === GuideStep.interval) name = 'Interval';
  if (type === GuideStep.longBreak) name = 'Long break';

  return (
    <Typography component={'p'} className={className} style={style}>
      {name}
    </Typography>
  );
})`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
  color: #fff;
`;
