import { styled } from '@mui/material';
import { CSSProperties } from 'react';
import { clsx } from 'clsx';
import Lottie from 'react-lottie';
import { LottieImage } from '../LottieImage';

interface LottieCardProps {
  className?: string;
  style?: CSSProperties;
  lottie: { [key: string]: any };
}

export const LottieCard = styled(({ className, style, lottie }: LottieCardProps) => {
  return (
    <div className={clsx('LottieCard', className)} style={style}>
      <LottieImage options={{ animationData: lottie }}></LottieImage>
    </div>
  );
})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 62px;
`;
