import { styled } from '@mui/material';
import { CountdownTimer } from '../CountdownTimer/CountdownTimer';
import { TimerWave } from '../TimerWave';
import { useAppSelector } from '../../redux/store';
import { selectTimerSetting } from '../../redux/timer/timerSlice';
import { useTimerContext } from '../../context/TimerContext';
import { SessionButtons } from '../TimerController';

export const Pomodoro = () => {
  const { sessionType, ticking } = useAppSelector(selectTimerSetting);
  const { progress } = useTimerContext();

  return (
    <PomodoroWrapper className='PomodoroWrapper'>
      <InterfaceWrapper className='InterfaceWrapper'>
        <TimerInterfaceWrapper>
          <CountdownTimer />
        </TimerInterfaceWrapper>
        <SessionButtonsWrapper>
          <SessionButtons></SessionButtons>
        </SessionButtonsWrapper>
      </InterfaceWrapper>
      <TimerWave ticking={ticking} value={progress} session={sessionType}></TimerWave>
    </PomodoroWrapper>
  );
};

const PomodoroWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: auto;
  position: relative;
`;

const InterfaceWrapper = styled('div')`
  position: relative;
  z-index: 1;
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TimerInterfaceWrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  padding: 16px 0 24px;
`;

const SessionButtonsWrapper = styled('div')`
  padding-bottom: 96px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding-bottom: 64px;
  }
`;
