import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';

export const ErrorFallback = (props: FallbackProps) => {
  // TODO: Push Notification in Slack

  useEffect(() => {
    console.log(props.error.message);
  }, []);

  return (
    <div>
      <p>Application error: a client-side exception has occurred</p>
      <p>(see the browser console for more information).</p>
      <p>error message : {props.error.message}</p>
      <button onClick={window.location.reload}>reload</button>
    </div>
  );
};
