import { styled, Typography } from '@mui/material';
import { CSSProperties, PropsWithChildren } from 'react';
import { LogoPixoSvg } from '../../assets';

export const Footer = () => {
  return (
    <Wrapper>
      <PixoLogo></PixoLogo>
      <Paragraph>
        <a href='https://www.iubenda.com/terms-and-conditions/36391786' target='_blank' rel='noreferrer'>
          Terms of Use
        </a>
        &nbsp;&{' '}
        <a href='https://www.iubenda.com/privacy-policy/36391786' target='_blank' rel='noreferrer'>
          Privacy
        </a>
        &nbsp;|&nbsp;
        <a href='mailto:support@pixo.co'>Support</a>
        &nbsp;
      </Paragraph>
      <Paragraph>Ⓒ PIXO Inc.</Paragraph>
    </Wrapper>
  );
};

const Wrapper = styled('footer')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 82px;
  color: white;
  column-gap: 8px;
  background-color: #28282d;
`;

interface PixoLogoProps {
  className?: string;
  style?: CSSProperties;
}

const PixoLogo = styled(({ className, style }: PixoLogoProps) => {
  return <img src={LogoPixoSvg} alt='Pixo Logo' className={className} style={style} width={100} height={100} />;
})`
  height: 20px;
`;

interface ParagraphProps extends PropsWithChildren {
  className?: string;
  style?: CSSProperties;
}

const Paragraph = styled(({ className, style, children }: ParagraphProps) => {
  return (
    <Typography component={'p'} className={className} style={style}>
      {children}
    </Typography>
  );
})`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #757575;
  a {
    text-decoration: underline;
  }
`;
