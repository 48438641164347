import { styled } from '@mui/material';
import { selectSetting } from '../../redux/setting/settingSlice';
import { useAppSelector } from '../../redux/store';
import { selectTimerSetting } from '../../redux/timer/timerSlice';
import styles from './Timer.module.scss';
import { TimeDisplay } from '../TimeDisplay';
import { useTimerContext } from '../../context/TimerContext';
import { PomodoroCounter } from '../PomodoroCounter';
import { PomodoroTypeLabel } from '../PomodoroTypeLabel';

export const Timer = () => {
  const { leftSeconds } = useTimerContext();
  const { sessionCount, sessionType } = useAppSelector(selectTimerSetting);
  const { longBreakInterval } = useAppSelector(selectSetting);

  return (
    <div className='Timer'>
      <StyledPomodoroTypeLabel value={sessionType}></StyledPomodoroTypeLabel>
      <StyledTimeDisplay value={leftSeconds}></StyledTimeDisplay>
      <PomodoroCounterWrapper>
        <PomodoroCounter value={sessionCount} total={longBreakInterval}></PomodoroCounter>
      </PomodoroCounterWrapper>
    </div>
  );
};

const StyledPomodoroTypeLabel = styled(PomodoroTypeLabel)`
  margin-bottom: 8px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
  }
`;

const StyledTimeDisplay = styled(TimeDisplay)`
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
  font-size: 200px;
  line-height: 240px;
  font-weight: 800;
  color: #000000c2;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    font-size: 120px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%; /* 132px */
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 96px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%; /* 105.6px */
  }
`;

const PomodoroCounterWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;
