import { CSSProperties } from 'react';
import { convertSecondToTimerCountdownNumber } from '../../utils/utils';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';

interface TimeDisplayProps {
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  style?: CSSProperties;
  value: number;
}

export const TimeDisplay = styled(({ as = 'div', className, style, value }: TimeDisplayProps) => {
  return (
    <Typography component={as} className={className} style={style}>
      {convertSecondToTimerCountdownNumber(value)}
    </Typography>
  );
})`
  user-select: none;
`;
