import { init } from '@amplitude/analytics-browser';
import { createTheme, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { ReactNode, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AudioContextProvider } from './context/AudioContext';
import { SettingContextProvider } from './context/SettingContext';
import './fonts.css';
import './index.css';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import './slick.css';
import { AMPLITUDE_KEY, LANG } from './utils/utils';
import {
  DESKTOP_BREAKPOINT_WIDTH,
  LAPTOP_BREAKPOINT_WIDTH,
  MOBILE_BREAKPOINT_WIDTH,
  TABLET_BREAKPOINT_WIDTH,
} from './constants/Breakpoints';

const version = process.env.REACT_APP_VERSION;
const rootElement = document.getElementById('root');
const basename = LANG ? `/${LANG}` : '/';

if (process.env.NODE_ENV === 'development') {
  console.log(`VERSION ${version}`);
}

if (AMPLITUDE_KEY) {
  init(AMPLITUDE_KEY);
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: MOBILE_BREAKPOINT_WIDTH,
      md: TABLET_BREAKPOINT_WIDTH,
      lg: LAPTOP_BREAKPOINT_WIDTH,
      xl: DESKTOP_BREAKPOINT_WIDTH,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiGrid: {
      defaultProps: {
        columns: {
          xs: 4,
          sm: 4,
          md: 8,
          lg: 12,
          xl: 12,
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: [
      'Pretendard',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#FF5C5C',
    },
  },
});

const Providers = (props: { children: ReactNode }) => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={1000}>
          <BrowserRouter basename={basename}>
            <HelmetProvider>
              <AudioContextProvider>
                <SettingContextProvider>{props.children}</SettingContextProvider>
              </AudioContextProvider>
            </HelmetProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  );
};

const Index = () => {
  return (
    <Providers>
      <App />
    </Providers>
  );
};

createRoot(rootElement as HTMLElement).render(<Index />);

reportWebVitals();
