import { styled } from '@mui/material';
import { CSSProperties, ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { SessionType } from '../../types';

export interface SessionTimeInputBlurArguments {
  session: SessionType;
  minutes: number;
  seconds: number;
}

/**
 * 시간을 입력할 수 있는 입력창입니다.
 */
interface InputProps {
  className?: string;
  style?: CSSProperties;
  initialValue: string;
  focused?: boolean;
  ticking?: boolean;
  session: SessionType;
  onChange?: (value: string) => void;
  onBlur?: (args: SessionTimeInputBlurArguments) => void;
}

export const SessionTimeInput = styled(
  ({ className, ticking, focused, style, session, initialValue, onBlur, onChange }: InputProps) => {
    const [value, setValue] = useState(initialValue);
    const isEditable = focused && !ticking;
    const handleChangeValue = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (e) => {
        const value = e.currentTarget.value;

        setValue(value);
        onChange?.(value);
      },
      [onChange],
    );
    const handleBlur = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (e) => {
        const value = e.currentTarget.value;

        if (Number.isNaN(Number(value))) return onBlur?.({ session, minutes: 1, seconds: 60 });
        if (Number(value) < 0) return onBlur?.({ session, minutes: 1, seconds: 60 });

        const payload: SessionTimeInputBlurArguments = {
          session,
          minutes: Number(value),
          seconds: Number(value) * 60,
        };

        onBlur?.(payload);
      },
      [session, onBlur],
    );

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <input
        type='number'
        value={value}
        className={className}
        style={{
          ...style,
          pointerEvents: !isEditable ? 'none' : undefined,
        }}
        contentEditable
        onBlur={handleBlur}
        onInput={handleChangeValue}
      ></input>
    );
  },
)`
  appearance: none;
  display: flex;
  align-items: center;
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  flex: 0 1 auto;
  outline: none;
  border: none;
  background-color: transparent;
  overflow: auto;
  color: #080808;
  ${({ focused, ticking }) => (focused && !ticking ? '' : 'pointer-events: none;')}
  ${({ focused }) => focused && 'color: #FFF3E2;'}
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
`;
