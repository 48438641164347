import { lazy } from 'react';

// ? Modal
export const LazyConfirmEmail = lazy(() =>
  import('../components/ConfirmEmail/ConfirmEmail').then((module) => ({ default: module.ConfirmEmail })),
);
export const LazySetPassword = lazy(() =>
  import('../components/SetPassword/SetPassword').then((module) => ({ default: module.SetPassword })),
);
export const LazyForgotPassword = lazy(() =>
  import('../components/ForgotPassword/ForgotPassword').then((module) => ({ default: module.ForgotPassword })),
);
export const LazyLogin = lazy(() => import('../components/Login/Login').then((module) => ({ default: module.Login })));
export const LazyProfile = lazy(() =>
  import('../components/Profile/Profile').then((module) => ({ default: module.Profile })),
);
export const LazyRegister = lazy(() =>
  import('../components/Register/Register').then((module) => ({ default: module.Register })),
);
export const LazyCharts = lazy(() =>
  import('../components/Chart/Chart').then((module) => ({ default: module.Charts })),
);
export const LazySetting = lazy(() =>
  import('../components/Setting/Setting').then((module) => ({ default: module.Setting })),
);
