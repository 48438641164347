import { Button, styled } from '@mui/material';
import { CSSProperties } from 'react';
import { TimerControlTrigger } from '../TimerControlTrigger';
import { useAppSelector } from '../../redux/store';
import { selectTimerSetting } from '../../redux/timer/timerSlice';

interface TimerControlButtonProps {
  className?: string;
  style?: CSSProperties;
}

export const TimerControlButton = styled(({ className, style }: TimerControlButtonProps) => {
  const { ticking } = useAppSelector(selectTimerSetting);

  return (
    <TimerControlTrigger className={className} style={style}>
      <Button type='button' color='primary' variant='contained'>
        {!ticking ? 'START' : 'PAUSE'}
      </Button>
    </TimerControlTrigger>
  );
})`
  button {
    border-radius: 16px;
    width: 120px;
    height: 84px;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    background-color: #0000008a;
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    button {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 27px */
      height: 56px;
      border-radius: 8px;
    }
  }
`;
