import { styled } from '@mui/material';
import { CSSProperties } from 'react';
import { LoadingFocusGif } from '../../assets';

interface PomodoroLoadingIndicatorProps {
  className?: string;
  style?: CSSProperties;
  loading?: boolean;
}

export const PomodoroLoadingIndicator = styled(({ className, style, loading }: PomodoroLoadingIndicatorProps) => {
  return (
    <div className={className} style={style}>
      <PomodoroLoading></PomodoroLoading>
    </div>
  );
})`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff3e2;
  z-index: 1;
`;

interface PomodoroLoadingProps {
  className?: string;
  style?: CSSProperties;
}

const PomodoroLoading = styled(({ className, style }: PomodoroLoadingProps) => {
  return (
    <img
      className={className}
      style={style}
      src={LoadingFocusGif}
      alt='loading focus keeper'
      title='loading focus keeper'
    />
  );
})`
  width: 160px;
  height: 160px;
`;
