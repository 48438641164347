import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './components/ErrorFallback/ErrorFallback';
import Router from './routes/Router';
import { TimerProvider } from './context/TimerContext';
import { GlobalStyles } from './components/GlobalStyles';

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <TimerProvider>
        <Router />
      </TimerProvider>
      <GlobalStyles></GlobalStyles>
    </ErrorBoundary>
  );
};

export default App;
