import { Grid, styled, Typography } from '@mui/material';
import { CSSProperties, PropsWithChildren, useRef } from 'react';
import { ImpactItem } from './ImpactItem';
import { LottieImpactFocus, LottieImpactManagement, LottieImpactProductivity } from '../../assets/lotties';
import { useInView, motion } from 'framer-motion';
import { TransitionVariants } from '../../constants/Transition';

const impactItems = [
  {
    title: 'Increased Focus',
    lottie: LottieImpactFocus,
    content:
      'By setting a timer and committing to focused work for short periods, you avoid checking email, social media or doing other tasks that reduce focus.',
  },
  {
    title: 'Better Time Management',
    lottie: LottieImpactManagement,
    content:
      'It allows for flexible scheduling. The technique can be adapted to any task, short or long, complex or simple. You decide how to allocate your time based on the work required.',
  },
  {
    title: 'Increased Productivity',
    lottie: LottieImpactProductivity,
    content:
      'The timed breaks help rejuvenate your mind and body, giving you an energy boost to start the next work period. This cycle of work and rest leads to increased stamina and productivity over time.',
  },
] as const;

export const Impact = () => {
  const sectionEl = useRef<HTMLElement>(null);
  const isInView = useInView(sectionEl, { once: true });

  return (
    <Background ref={sectionEl}>
      <Wrapper>
        <Grid container justifyContent={'center'}>
          <Grid item lg={12} md={6} xs={4}>
            <motion.div
              variants={TransitionVariants}
              initial={'fadeOut'}
              animate={isInView && 'fadeIn'}
              transition={{
                duration: 0.4,
              }}
            >
              <Title>Ignore the noise, Focus on your Time.</Title>
            </motion.div>
          </Grid>
          <Grid item lg={12} md={6} xs={4}>
            <List>
              {impactItems.map(({ title, content, lottie }, index) => {
                return (
                  <ListItem key={index}>
                    <ImpactItem lottie={lottie} title={title} content={content}></ImpactItem>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Wrapper>
    </Background>
  );
};

const Background = styled('section')`
  background-color: var(--current-session-color);
  transition: var(--background-transition-duration) background-color;
`;

const Wrapper = styled('article')`
  padding: 160px 67px 120px;
  max-width: 1366px;
  width: 100%;
  margin: auto;
  ${({ theme }) => `${theme.breakpoints.down('lg')}`} {
    padding: 100px 80px;
  }
  ${({ theme }) => `${theme.breakpoints.down('md')}`} {
    padding: 100px 20px;
  }
`;

interface TitleProps extends PropsWithChildren {
  className?: string;
  style?: CSSProperties;
}

const Title = styled(({ children, className, style }: TitleProps) => {
  return (
    <Typography className={className} style={style} component={'h3'}>
      {children}
    </Typography>
  );
})`
  color: #fff;
  font-size: 80px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;

  ${({ theme }) => `${theme.breakpoints.down('lg')} {
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 52.8px */
  }`}
`;

const List = styled('ul')`
  margin-top: 100px;
  list-style: none;
`;

const ListItem = styled('li')`
  margin-bottom: 100px;
  &:last-of-type {
    margin-bottom: 0;
  }
  ${({ theme }) => `${theme.breakpoints.down('lg')} {
    margin-bottom: 80px;
  }`}
`;
