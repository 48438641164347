import { Variants } from 'framer-motion';

export const TransitionVariants: Variants = {
  fadeIn: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  fadeOut: {
    opacity: 0,
    transform: 'translateY(64px)',
  },
};
