import type { Payload, Event } from '@amplitude/analytics-types';
import axios from 'axios';
import * as Realm from 'realm-web';
import { DBMetaDataColumns, TableNames } from '../types';
import { AMPLITUDE_KEY } from '../utils/utils';
import { UserChart, UserChartLocal, UserProfile, UserProfileLocal, UserSetting, UserSettingLocal } from './interface';

export const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID as string;
export const ATLAS_CLUSTER = process.env.REACT_APP_ATLAS_CLUSTER as string;
export const ATLAS_DATABASE_NAME = process.env.REACT_APP_ATLAS_DATABASE_NAME as string;
export const CONFIRM_PAGE_URL = process.env.REACT_APP_AUTH_REDIRECT_SET_PASSWORD as string;

export const getApp = Realm.getApp(REALM_APP_ID);

// ? auth

export const register = async (email: string, password: string): Promise<void> => {
  return getApp.emailPasswordAuth.registerUser({ email, password });
};

export const login = async (email: string, password: string): Promise<Realm.User> => {
  const credential = Realm.Credentials.emailPassword(email, password);
  const user = await getApp.logIn(credential);

  return user;
};

export const googleLogin = async (): Promise<Realm.User> => {
  const credential = Realm.Credentials.google({ redirectUrl: CONFIRM_PAGE_URL });
  const user = await getApp.logIn(credential);

  return user;
};

export const logout = async (user: Realm.User): Promise<void> => {
  return user.logOut();
};

// ? auth.api

export const onlyResetPassword = async (email: string, password: string): Promise<void> => {
  return getApp.emailPasswordAuth.callResetPasswordFunction({ email, password }, { type: 'recovery' });
};

export const resetPasswordWithEmail = async (email: string, password: string): Promise<void> => {
  return getApp.emailPasswordAuth.callResetPasswordFunction({ email, password });
};

export const deleteUser = async (user: Realm.User): Promise<void> => {
  return getApp.deleteUser(user);
};

export const refreshCustomData = async (user: Realm.User): Promise<SimpleObject> => {
  return user.refreshCustomData();
};

export const confirmUser = async (token: string, tokenId: string): Promise<void> => {
  return getApp.emailPasswordAuth.confirmUser({ token, tokenId });
};

export const handleAuthRedirect = () => {
  return Realm.handleAuthRedirect();
};

// ? from

export const getMongoDB = (user: Realm.User) => {
  return user.mongoClient(ATLAS_CLUSTER).db(ATLAS_DATABASE_NAME);
};

export const getProfileDB = (user: Realm.User) => {
  return getMongoDB(user).collection<UserProfile>(TableNames.profile);
};

export const getSettingDB = (user: Realm.User) => {
  return getMongoDB(user).collection<UserSetting>(TableNames.setting);
};

export const getChartDB = (user: Realm.User) => {
  return getMongoDB(user).collection<UserChart>(TableNames.chart);
};

export const selectProfileFromDB = async (user: Realm.User): Promise<UserProfile | null> => {
  return getProfileDB(user).findOne({ user_id: user.id });
};

export const selectSettingFromDB = async (user: Realm.User): Promise<UserSetting | null> => {
  return getSettingDB(user).findOne({ user_id: user.id });
};

// ! indexing 등 리서치 필요
export const selectChartsFromDB = async (user: Realm.User, gt: Date, lte: Date): Promise<UserChart[]> => {
  return getChartDB(user).find({ user_id: user.id, date: { $gt: gt, $lte: lte } }, { sort: { date: 1 } });
};

export const insertProfileFromDB = async (user: Realm.User, profile: UserProfileLocal) => {
  const date = new Date();

  return getProfileDB(user).insertOne({ user_id: user.id, created_at: date, updated_at: date, ...profile });
};

export const insertSettingFromDB = async (user: Realm.User, setting: UserSettingLocal) => {
  const date = new Date();

  return getSettingDB(user).insertOne({ user_id: user.id, created_at: date, updated_at: date, ...setting });
};

export const insertChartFromDB = async (user: Realm.User, chart: UserChartLocal) => {
  const date = new Date();
  return getChartDB(user).insertOne({ user_id: user.id, created_at: date, updated_at: date, ...chart });
};

export const insertChartsFromDB = async (user: Realm.User, charts: UserChartLocal[]) => {
  const date = new Date();

  return getChartDB(user).insertMany(
    charts.map((chart) => ({ user_id: user.id, created_at: date, updated_at: date, ...chart })),
  );
};

export const updateProfileFromDB = async (user: Realm.User, changes: Partial<UserProfile>) => {
  const date = new Date();
  const profile = { ...changes, [DBMetaDataColumns.updatedAt]: date } as Partial<UserProfileLocal>;

  return getProfileDB(user).updateOne({ user_id: user.id }, { $set: profile }, { upsert: true });
};

export const updateSettingFromDB = async (user: Realm.User, changes: Partial<UserSettingLocal>) => {
  const date = new Date();
  const setting = { ...changes, [DBMetaDataColumns.updatedAt]: date } as Partial<UserProfileLocal>;

  return getSettingDB(user).updateOne({ user_id: user.id }, { $set: setting });
};

export const deleteDataFromDB = async (user: Realm.User): Promise<void> => {
  const dbs = [getProfileDB, getSettingDB, getChartDB];

  await Promise.all(dbs.map((dbFunc) => dbFunc(user).deleteMany({ user_id: user.id })));
};

// ? Amplitude

export const batchLogEvent = (events: Event[]) => {
  return axios.post('https://api2.amplitude.com/batch', { api_key: AMPLITUDE_KEY, events, options: {} } as Payload);
};
