import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getApp } from '../../api/api';
import { SliceType } from '../../types';
import { RootState } from '../store';

interface UserState {
  username: string;
  userId: string | null;
}

const initialState = {
  username: '',
  userId: null,
} as UserState;

export const userSlice = createSlice({
  name: SliceType.user,
  initialState,
  reducers: {
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    setUserId: (state, action: PayloadAction<string | null>) => {
      state.userId = action.payload;
    },
  },
});

export const { setUsername, setUserId } = userSlice.actions;

const selectUserSlice = (state: RootState) => state.user;

export const selectUsername = createSelector(selectUserSlice, (userState) => userState.username);

export const selectUserId = createSelector(selectUserSlice, (userState) => userState.userId);

export const selectUser = createSelector(selectUserSlice, () => getApp.currentUser);

export default userSlice.reducer;
