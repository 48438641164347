import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useElapsedTime } from 'use-elapsed-time';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  selectCurrentTimerInfo,
  selectTimerInfos,
  selectTimerSetting,
  setFocusInfo,
  setLongBreakInfo,
  setSessionCount,
  setSessionType,
  setShortBreakInfo,
  setTicking,
} from '../../redux/timer/timerSlice';
import { selectSetting } from '../../redux/setting/settingSlice';
import { NotificationType, SessionType, TaxonomyEventActions, TaxonomyEventNames } from '../../types';
import { AUTO_START_DELAY_IN_MILLISECOND, GOAL_PER_DAY, combine } from '../../utils/utils';
import { AudioContext } from '../AudioContext';
import { timerWorker } from '../../workers';
import nosleep from 'nosleep.js';
import { selectAudioInfos } from '../../redux/audio/audioSlice';
import { useUpdateChartData } from '../../hooks/useUpdateChartData';
import { logEvent } from '@amplitude/analytics-browser';
import { selectTodayChart } from '../../redux/charts/chartsSlice';
import { useNotification } from '../../hooks/useNotification';

interface TimerContextArguments {
  leftSeconds: number;
  progress: number;
  startTimer: (session: SessionType) => void;
  pauseTimer: () => void;
  changeSession: (session: SessionType) => void;
  mute: (session: SessionType) => void;
  unmute: (session: SessionType) => void;
}

export const TimerContext = createContext<TimerContextArguments | undefined>(undefined);

export const TimerProvider = ({ children }: PropsWithChildren) => {
  const { autoStartBreak, autoStartFocus, longBreakInterval, notificationType, notificationMinutes } =
    useAppSelector(selectSetting);
  const { ticking, sessionType, sessionCount } = useAppSelector(selectTimerSetting);
  const { second } = useAppSelector(selectCurrentTimerInfo);
  const { focus, shortBreak, longBreak } = useAppSelector(selectTimerInfos);
  const { alarmAudioInfo } = useAppSelector(selectAudioInfos);
  const { alarmAudioState, tickingAudioState } = useContext(AudioContext);
  const [elapsedTime, setElapsedTime] = useState(0);
  const { goal_count: goalCount } = useAppSelector(selectTodayChart);
  const noSleep = useRef(new nosleep());
  const { onChangeChartsData } = useUpdateChartData();
  const dispatch = useAppDispatch();
  const [sessionStartDate, setSessionStartDate] = useState(new Date());
  const { showNotification } = useNotification();
  const [sendedNotification, setSendedNotification] = useState(false);

  const leftSeconds = Math.ceil(second - elapsedTime);
  const progress = elapsedTime / second;

  /**
   * PC가 자동적으로 수면모드에 들어가는 것을 방지하는 모드를 활성 / 비활성할 수 있습니다.
   */
  const changeNoSleepMode = useCallback((isActive: boolean) => {
    isActive ? noSleep.current.enable() : noSleep.current.disable();
  }, []);

  /**
   * 설정해 둔 알림시간이 되었을 때 알림을 전송하는 여부를 활성 / 비활성할 수 있습니다.
   */
  const setActiveNotification = useCallback(
    (isActive: boolean) => {
      if (isActive) timerWorker.playTimer(leftSeconds, { notificationType, notificationMinutes });
      else timerWorker.pauseTimer();
    },
    [leftSeconds, notificationType, notificationMinutes],
  );

  /**
   * 1초가 지날 때 마다 효과음을 재생하는 기능을 활성 / 비활성할 수 있습니다.
   */
  const setActiveTickingSound = useCallback(
    (isActive: boolean) => {
      if (isActive) {
        tickingAudioState.audio.stop(tickingAudioState.id);
        if (tickingAudioState.audio.state()) {
          tickingAudioState.changeId(tickingAudioState.audio.play());
        } else {
          tickingAudioState.audio.once('load', () => {
            tickingAudioState.changeId(tickingAudioState.audio.play());
          });
        }
      } else {
        tickingAudioState.audio.pause();
      }
    },
    [tickingAudioState],
  );

  const logTimerStart = useCallback((session: SessionType) => {
    logEvent(combine([TaxonomyEventActions.start, TaxonomyEventNames[`${session}_session`]]));
  }, []);

  const muteTickingSound = useCallback(
    (session: SessionType) => {
      let currentMute = focus.mute;
      if (session === SessionType.shortBreak) currentMute = shortBreak.mute;
      if (session === SessionType.longBreak) currentMute = longBreak.mute;

      tickingAudioState.audio.mute(currentMute, tickingAudioState.id);
    },
    [focus, shortBreak, longBreak, tickingAudioState],
  );

  const mute = useCallback(() => {
    muteTickingSound(sessionType);
    if (sessionType === SessionType.focus) dispatch(setFocusInfo({ mute: true }));
    if (sessionType === SessionType.shortBreak) dispatch(setShortBreakInfo({ mute: true }));
    if (sessionType === SessionType.longBreak) dispatch(setLongBreakInfo({ mute: true }));
  }, [dispatch, muteTickingSound, sessionType]);

  const unmute = useCallback(() => {
    muteTickingSound(sessionType);
    if (sessionType === SessionType.focus) dispatch(setFocusInfo({ mute: false }));
    if (sessionType === SessionType.shortBreak) dispatch(setShortBreakInfo({ mute: false }));
    if (sessionType === SessionType.longBreak) dispatch(setLongBreakInfo({ mute: false }));
  }, [dispatch, muteTickingSound, sessionType]);

  const startTimer = useCallback(
    (session: SessionType) => {
      setSessionStartDate(new Date());
      dispatch(setTicking(true));
      changeNoSleepMode(true);
      setActiveNotification(true);
      setActiveTickingSound(true);
      logTimerStart(session);
    },
    [dispatch, changeNoSleepMode, setActiveNotification, setActiveTickingSound, logTimerStart],
  );

  const pauseTimer = useCallback(() => {
    dispatch(setTicking(false));
    changeNoSleepMode(false);
    setActiveNotification(false);
    setActiveTickingSound(false);
  }, [dispatch, changeNoSleepMode, setActiveNotification, setActiveTickingSound]);

  /**
   * 타이머가 다음 세션으로 진입하면서 자동 재생을 진행할지를 결정하는 함수입니다.
   */
  const autoStart = useCallback(
    (nextSession: SessionType) => {
      window.setTimeout(() => {
        if (nextSession === SessionType.shortBreak && autoStartBreak) startTimer(nextSession);
        else if (nextSession === SessionType.focus && autoStartFocus) startTimer(nextSession);
      }, AUTO_START_DELAY_IN_MILLISECOND);
    },
    [autoStartBreak, autoStartFocus, startTimer],
  );

  const changeSession = useCallback(
    (session: SessionType) => {
      setSendedNotification(false);
      dispatch(setSessionType(session));
      timerWorker.setSeconds(second);
    },
    [dispatch, second],
  );

  /**
   * 다음 세션에 진입합니다.
   */
  const changeNextSession = useCallback(() => {
    if (sessionType !== SessionType.focus) {
      changeSession(SessionType.focus);
      return SessionType.focus;
    }

    if (sessionCount >= longBreakInterval - 1) {
      changeSession(SessionType.longBreak);
      return SessionType.longBreak;
    }

    changeSession(SessionType.shortBreak);
    return SessionType.shortBreak;
  }, [changeSession, longBreakInterval, sessionCount, sessionType]);

  /**
   * 타이머가 종료 될 때 알람을 울립니다.
   * 반복 설정을 했다면 반복 설정 횟수만큼 알람을 반복합니다.
   */
  const soundAlarmOnTimerEndWithRepeat = useCallback(() => {
    let leftRepeatCount = alarmAudioInfo.repeat ? (alarmAudioInfo.repeat < 1 ? 1 : alarmAudioInfo.repeat) : 1;

    const play = () => {
      leftRepeatCount -= 1;

      if (leftRepeatCount < 1) {
        alarmAudioState.audio.off('end', play);
        return;
      }
      alarmAudioState.audio.play();
    };

    alarmAudioState.audio.on('end', play);
    alarmAudioState.audio.play();
  }, [alarmAudioInfo, alarmAudioState]);

  /**
   * 차트에 세션 완료 사실을 기록합니다.
   */
  const recordChart = useCallback(
    (sessionStartDate: Date, currentSession: SessionType) => {
      const focusSeconds = focus.second;
      const todayGoal = goalCount / longBreakInterval;

      if (!document.hidden) {
        onChangeChartsData([
          {
            date: sessionStartDate,
            focus_duration: currentSession === SessionType.focus ? focusSeconds : 0,
            break_duration: currentSession !== SessionType.focus ? todayGoal : 0,
          },
        ]);

        if (currentSession === SessionType.focus) {
          logEvent(combine([TaxonomyEventActions.complete, TaxonomyEventNames.focus_session]));
        } else if (currentSession === SessionType.shortBreak) {
          logEvent(combine([TaxonomyEventActions.complete, TaxonomyEventNames.short_break]));
        } else if (currentSession === SessionType.longBreak) {
          logEvent(combine([TaxonomyEventActions.complete, TaxonomyEventNames.long_break]));
        }

        if (sessionCount === longBreakInterval) {
          logEvent(combine([TaxonomyEventActions.complete, TaxonomyEventNames.round]));
        }

        if (todayGoal === GOAL_PER_DAY) {
          logEvent(combine([TaxonomyEventActions.complete, TaxonomyEventNames.today_goal]));
        }
      }
    },
    [focus, goalCount, longBreakInterval, sessionCount, onChangeChartsData],
  );

  const sendNotification = useCallback(
    (leftSeconds: number) => {
      const notificationSeconds = notificationMinutes * 60;

      if (!sendedNotification && ticking) {
        if (notificationType === NotificationType.every) {
          if (leftSeconds <= notificationSeconds) {
            showNotification(`${notificationMinutes}minute(s) left`);
            setSendedNotification(true);
          }
        } else {
          if (sessionType === SessionType.longBreak && leftSeconds <= notificationSeconds) {
            showNotification(`${notificationMinutes}minute(s) left`);
            setSendedNotification(true);
          }
        }
      }
    },
    [showNotification, sessionType, notificationType, notificationMinutes, sendedNotification, ticking],
  );

  /**
   * 현재 진행한 세션 카운트를 설정합니다.
   */
  const changeSessionCount = useCallback(() => {
    const nextSession = sessionCount + 1;

    if (sessionType === SessionType.focus) {
      dispatch(setSessionCount(nextSession < longBreakInterval ? nextSession : longBreakInterval));
    }
    if (sessionType === SessionType.longBreak) {
      dispatch(setSessionCount(sessionCount <= longBreakInterval ? 0 : sessionCount));
    }
  }, [dispatch, sessionType, longBreakInterval, sessionCount]);

  const updateTimer = useCallback(
    (elapsedTime: number) => {
      setElapsedTime(elapsedTime);
      sendNotification(leftSeconds);
      muteTickingSound(sessionType);
    },
    [sendNotification, muteTickingSound, leftSeconds, sessionType],
  );

  /**
   * 타이머가 종료 될 때 실행되는 이벤트입니다.
   */
  const endTimer = useCallback(() => {
    const nextSession = changeNextSession();

    pauseTimer();
    autoStart(nextSession);
    changeSessionCount();
    soundAlarmOnTimerEndWithRepeat();
    recordChart(sessionStartDate, sessionType);
    setSendedNotification(false);
  }, [
    changeSessionCount,
    autoStart,
    changeNextSession,
    soundAlarmOnTimerEndWithRepeat,
    recordChart,
    pauseTimer,
    sessionStartDate,
    sessionType,
  ]);

  const { reset } = useElapsedTime({
    isPlaying: ticking,
    duration: ticking ? second : undefined,
    startAt: second,
    onUpdate: updateTimer,
    onComplete: endTimer,
  });

  /**
   * 타이머의 세션이 변경되면 타이머의 유지시간을 0초로 리셋하여 다시 처음부터 카운트합니다.
   */
  useEffect(() => {
    reset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionType]);

  return (
    <TimerContext.Provider
      value={{
        leftSeconds,
        progress,
        startTimer,
        pauseTimer,
        changeSession,
        mute,
        unmute,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};
