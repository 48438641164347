import { createSelector, createSlice } from '@reduxjs/toolkit';
import { Theme } from '../../theme/interface';
import { theme } from '../../theme/theme';
import { SliceType, ThemeType } from '../../types';
import { RootState } from '../store';

export type ThemeState = { [key in ThemeType]: Theme };

const initialState = { ...theme } as ThemeState;

export const themeSlice = createSlice({
  name: SliceType.theme,
  initialState,
  reducers: {},
});

export const selectCurrentTheme = createSelector(
  (state: RootState) => state.setting,
  (state: RootState) => state.theme,
  ({ darkMode }, { dark, light }) => {
    return darkMode ? dark : light;
  },
);

export default themeSlice.reducer;
