import { useMediaQuery, useTheme } from '@mui/system';
import { ReactNode } from 'react';

interface ResponsiveVisibilityProps {
  type: 'down' | 'up' | 'only';
  responsive: { [key: string]: ReactNode };
}

export const ResponsiveVisibility = ({ type, responsive }: ResponsiveVisibilityProps) => {
  const { breakpoints } = useTheme();
  const matchedXL = useMediaQuery(breakpoints[type]('xl'));
  const matchedLG = useMediaQuery(breakpoints[type]('lg'));
  const matchedMD = useMediaQuery(breakpoints[type]('md'));
  const matchedSM = useMediaQuery(breakpoints[type]('sm'));
  const matchedXS = useMediaQuery(breakpoints[type]('xs'));

  return (
    <>
      {matchedXL && responsive['xl']}
      {matchedLG && responsive['lg']}
      {matchedMD && responsive['md']}
      {matchedSM && responsive['sm']}
      {matchedXS && responsive['xs']}
    </>
  );
};
