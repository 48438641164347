import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserChart } from '../../api/interface';
import { SliceType } from '../../types';
import { combineChartsByDate, getTodayChart } from '../../utils/utils';
import { RootState } from '../store';

export interface ChartState {}

const chartEntityAdapter = createEntityAdapter<UserChart>({
  selectId: (chart) => chart._id,
  sortComparer: (a, b) => (a.date > b.date ? 1 : -1),
});

const initialState = chartEntityAdapter.getInitialState({} as ChartState);

export const chartSlice = createSlice({
  name: SliceType.chart,
  initialState,
  reducers: {
    setCharts: (state, action: PayloadAction<UserChart[]>) => {
      chartEntityAdapter.removeAll(state);
      chartEntityAdapter.setMany(state, action.payload);
    },
    insertChart: (state, action: PayloadAction<UserChart>) => {
      chartEntityAdapter.addOne(state, action.payload);
    },
    insertCharts: (state, action: PayloadAction<UserChart[]>) => {
      chartEntityAdapter.addMany(state, action.payload);
    },
    upsertCharts: (state, action: PayloadAction<UserChart[]>) => {
      chartEntityAdapter.upsertMany(state, action.payload);
    },
  },
});

export const { setCharts, insertChart, insertCharts, upsertCharts } = chartSlice.actions;

export const { selectAll: selectAllCharts } = chartEntityAdapter.getSelectors<RootState>((state) => state.chart);

export const selectChartsByDate = createSelector(selectAllCharts, combineChartsByDate);

export const selectTodayChart = createSelector(selectChartsByDate, getTodayChart);

export default chartSlice.reducer;
