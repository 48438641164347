import { AudioInfo, TimerInfo } from './interface';

export enum Paths {
  home = '/',
  confirmEmail = '/confirm-email',
  setPassword = '/set-password',
  modal = '/modal',
}

export enum SessionType {
  focus = 'focus',
  shortBreak = 'shortBreak',
  longBreak = 'longBreak',
}

export enum TimerColorType {
  focus = '#fa3338',
  shortBreak = '#6e81e7',
  longBreak = '#606373',
}

export enum AudioType {
  alarm = 'alarm',
  ticking = 'ticking',
}

export type TimerSetting = {
  [key in keyof typeof SessionType]: TimerInfo;
};

export type AudioSetting = {
  [key in keyof typeof AudioType]: AudioInfo;
};

export enum SliceType {
  timer = 'timer',
  audio = 'audio',
  setting = 'setting',
  theme = 'theme',
  modal = 'modal',
  chart = 'chart',
  user = 'user',
}

export enum NotificationType {
  last = 'last',
  every = 'every',
}

export enum ThemeType {
  light = 'light',
  dark = 'dark',
}

export enum AutoStartType {
  autoStartBreak = 'autoStartBreak',
  autoStartFocus = 'autoStartFocus',
}

export enum ModalType {
  options = 'options',
  charts = 'charts',
  profile = 'profile',
  login = 'login',
  register = 'register',
  forgotPassword = 'forgotPassword',
}

export enum ChartType {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}

export enum ChartOverviewDate {
  daily = 1,
  weekly = 7,
  monthly = 30,
}

export enum TableNames {
  profile = 'profile',
  chart = 'report',
  setting = 'setting',
}

export enum DBDocumentColumns {
  id = '_id',
  userId = 'user_id',
}

export enum DBMetaDataColumns {
  createdAt = 'created_at',
  updatedAt = 'updated_at',
}

export enum DBProfileColumns {
  name = 'name',
  lastLoginTimestamp = 'last_login_timestamp',
}

export enum DBSettingColumns {
  focusDuration = 'focus_duration',
  shortBreakDuration = 'short_break_duration',
  longBreakDuration = 'long_break_duration',
  autostartFocus = 'autostart_pomodoro',
  autostartBreak = 'autostart_break',
  notificationType = 'notification_type',
  notificationMinutes = 'notification_minutes',
  darkMode = 'dark_mode',
  alarmSound = 'alarm_sound',
  alarmVolume = 'alarm_volume',
  alarmRepeat = 'alarm_repeat',
  tickingSound = 'ticking_sound',
  tickingVolume = 'ticking_volume',
  longBreakInterval = 'long_break_interval',
}

export enum DBChartColumns {
  date = 'date',
  focusDuration = 'focus_duration',
  breakDuration = 'break_duration',
}

export enum TaxonomyEventActions {
  create = 'create',
  complete = 'complete',
  start = 'start',
  tap = 'tap',
}

export enum TaxonomyEventNames {
  timer = 'timer',
  round = 'round',
  today_goal = 'today_goal',

  focus_session = 'focus_session',
  longBreak_session = 'longBreak_session',
  shortBreak_session = 'shortBreak_session',

  focus = 'focus',
  short_break = 'short_break',
  long_break = 'long_break',

  charts = 'charts',
  charts_daily = 'charts_daily',
  charts_monthly = 'charts_monthly',
  charts_weekly = 'charts_weekly',
  next_date = 'next_date',
  prev_date = 'prev_date',

  options = 'options',

  auto_start_break = 'auto_start_break',
  auto_start_focus = 'auto_start_focus',

  account = 'account',
  login = 'login',
  pixo = 'pixo',
  profile = 'profile',
  appdownload = 'appdownload',
  download_ios = 'download_ios',
  download_aos = 'download_aos',
}

export enum TaxonomyEventDetails {
  play = 'play',
  pause = 'pause',
  on = 'on',
  off = 'off',
  done = 'done',
  close = 'close',
}
