import { Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { CSSProperties, PropsWithChildren, useRef } from 'react';
import { clsx } from 'clsx';
import { TimerSteps } from '../TimerSteps';
import { LottieCard } from './LottieCard';
import { Lottie02, Lottie03, Lottie04, Lottie05 } from '../../assets/lotties';
import { GuideStep } from '../../types/randing';
import { motion, useInView } from 'framer-motion';
import { ResponsiveVisibility } from '../ResponsiveVisibility/ResponsiveVisibility';
import { TransitionVariants } from '../../constants/Transition';

interface ServiceGuideProps {
  className?: string;
  style?: CSSProperties;
}

export const ServiceGuide = styled(({ className, style }: ServiceGuideProps) => {
  return (
    <section className={clsx('ServiceGuide', className)} style={style}>
      <ServiceItem step={GuideStep.focus} />
      <ServiceItem step={GuideStep.shortBreak} reverse />
      <ServiceItem step={GuideStep.interval} />
      <ServiceItem step={GuideStep.longBreak} reverse />
    </section>
  );
})`
  display: flex;
  flex-direction: column;
  row-gap: 160px;
  padding: 160px 0;
  background-color: var(--background-color);
`;

interface ServiceItemProps {
  className?: string;
  style?: CSSProperties;
  step: GuideStep;
  reverse?: boolean;
}

const ServiceItem = styled(({ className, style, step, reverse }: ServiceItemProps) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('lg'));
  const articleEl = useRef<HTMLElement>(null);
  const isInView = useInView(articleEl, { once: true, amount: isMobile ? 0.2 : 0.5 });

  let lottie: { [key: string]: any } = Lottie02;
  if (step === GuideStep.shortBreak) lottie = Lottie03;
  if (step === GuideStep.interval) lottie = Lottie04;
  if (step === GuideStep.longBreak) lottie = Lottie05;

  let description = 'Set a timer to 25 minutes and start';
  if (step === GuideStep.shortBreak) description = 'Take a 5-minute short break';
  if (step === GuideStep.interval) description = 'Aim for 4 focus sessions';
  if (step === GuideStep.longBreak) description = 'Take a 15- to 30-minute long break';

  return (
    <article ref={articleEl}>
      <Grid className={className} style={style} container justifyContent={'center'} alignItems={'center'}>
        <Grid item lg={4} md={6} xs={4} order={isMobile ? 2 : reverse ? 2 : 0}>
          <motion.div
            variants={TransitionVariants}
            initial={'fadeOut'}
            animate={isInView && 'fadeIn'}
            transition={{
              delay: isMobile ? 0.1 : 0,
              duration: 0.5,
            }}
          >
            <ResponsiveVisibility
              type='up'
              responsive={{
                lg: <StyledTimerSteps step={step}></StyledTimerSteps>,
              }}
            />
            <Description>{description}</Description>
          </motion.div>
        </Grid>
        <Grid item lg={1} xs={0} order={1}></Grid>
        <Grid item lg={5} md={6} xs={4} order={isMobile ? 0 : reverse ? 0 : 2}>
          <motion.div
            variants={TransitionVariants}
            initial={'fadeOut'}
            animate={isInView && 'fadeIn'}
            transition={{
              delay: isMobile ? 0 : 0.1,
              duration: 0.5,
            }}
          >
            <ResponsiveVisibility
              type='down'
              responsive={{
                lg: <StyledTimerSteps step={step}></StyledTimerSteps>,
              }}
            />
            <LottieCard lottie={lottie}></LottieCard>
          </motion.div>
        </Grid>
      </Grid>
    </article>
  );
})`
  padding: 0 20px;
  max-width: 1366px;
  margin: auto;
`;

interface StyledTimerStepsProps {
  className?: string;
  style?: CSSProperties;
  step: GuideStep;
}

const StyledTimerSteps = styled(({ className, style, step }: StyledTimerStepsProps) => {
  return <TimerSteps className={className} style={style} step={step}></TimerSteps>;
})`
  display: inline-flex;
  margin: 0 auto 24px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface DescriptionProps extends PropsWithChildren {
  className?: string;
  style?: CSSProperties;
}

const Description = styled(({ className, style, children }: DescriptionProps) => {
  return (
    <Typography className={className} style={style} component={'p'}>
      {children}
    </Typography>
  );
})`
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 67.2px */
  color: #3d3d3d;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 46.8px */
    margin-top: 36px;
    text-align: center;
  }
`;
