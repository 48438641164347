import { Button, styled } from '@mui/material';
import { CSSProperties } from 'react';
import InlineSVG from 'react-inlinesvg';
import { MuteOffIcon, MuteOnIcon } from '../../assets';
import { MuteTickingSoundTrigger } from '../MuteTickingSoundTrigger';

interface MuteButtonProps {
  className?: string;
  style?: CSSProperties;
}

export const MuteButton = styled(({ className, style }: MuteButtonProps) => {
  return (
    <MuteTickingSoundTrigger>
      {({ isMute }) => (
        <Button disableRipple color='inherit' size='small' variant='contained' className={className} style={style}>
          <MuteIcon mute={isMute}></MuteIcon>
        </Button>
      )}
    </MuteTickingSoundTrigger>
  );
})`
  width: 20px;
  height: 20px;
  padding: 0;
  min-width: auto;
  height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0000000f !important;
  cursor: pointer;
`;

interface MuteIconProps {
  className?: string;
  style?: CSSProperties;
  mute: boolean;
}

export const MuteIcon = styled(({ className, style, mute }: MuteIconProps) => {
  const muteIconSrc = mute ? MuteOffIcon : MuteOnIcon;

  return <InlineSVG src={muteIconSrc} width={12} height={12} className={className} style={style}></InlineSVG>;
})`
  width: 12px;
`;
