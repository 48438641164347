import { useCallback, useMemo } from 'react';
import { UserSettingLocal } from '../api/interface';
import { updateAudioInfos } from '../redux/audio/audioSlice';
import { updateSettingData } from '../redux/setting/settingSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { updateTimerInfosSecond } from '../redux/timer/timerSlice';
import { selectUser } from '../redux/user/userSlice';
import { defaultSetting, getAlarmSound, getTickingSound } from '../utils/utils';
import { useDBSetting } from './useDBSetting';
import { useLocalSetting } from './useLocalSetting';

export const useSetting = () => {
  const dispatch = useAppDispatch();
  const dbSetting = useDBSetting();
  const localSetting = useLocalSetting();
  const user = useAppSelector(selectUser);
  const currentSetting = useMemo(() => {
    return (user ? dbSetting.setting : localSetting.setting) ?? defaultSetting;
  }, [user, dbSetting.setting, localSetting.setting]);

  const updateSettingToStore = useCallback(
    (_setting: Partial<UserSettingLocal>) => {
      const setting = { ...currentSetting, ..._setting };

      dispatch(
        updateTimerInfosSecond([setting.focus_duration, setting.short_break_duration, setting.long_break_duration]),
      );
      dispatch(
        updateAudioInfos({
          alarm: {
            sound: getAlarmSound(setting.alarm_sound).name,
            volume: setting.alarm_volume,
            repeat: setting.alarm_repeat,
          },
          ticking: {
            sound: getTickingSound(setting.ticking_sound).name,
            volume: setting.ticking_volume,
          },
        }),
      );
      dispatch(
        updateSettingData({
          autoStartBreak: setting.autostart_break,
          autoStartFocus: setting.autostart_pomodoro,
          darkMode: setting.dark_mode,
          notificationType: setting.notification_type,
          notificationMinutes: setting.notification_minutes,
          longBreakInterval: setting.long_break_interval,
        }),
      );
    },
    [currentSetting, dispatch],
  );

  return { dbSetting, localSetting, currentSetting, updateSettingToStore } as const;
};
