import { styled } from '@mui/material';
import { SessionType } from '../../types';
import { CSSProperties } from 'react';
import InlineSVG from 'react-inlinesvg';
import { FocusTimeIcon, LongTimeIcon, ShortTimeIcon } from '../../assets';

interface TimerIconProps {
  className?: string;
  style?: CSSProperties;
  session: SessionType;
  focused?: boolean;
}

export const TimerIcon = styled(({ className, style, session }: TimerIconProps) => {
  let iconSrc = FocusTimeIcon;
  if (session === SessionType.shortBreak) iconSrc = ShortTimeIcon;
  if (session === SessionType.longBreak) iconSrc = LongTimeIcon;

  return <InlineSVG className={className} style={style} src={iconSrc}></InlineSVG>;
})`
  width: 24px;
  height: 24px;
  path {
    ${({ focused }) => focused && `fill: #FFF3E2 !important;`}
  }
`;
