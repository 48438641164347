import { ThemeType } from '../types';
import { Theme } from './interface';
import {
  FocusKeeperTextIcon,
  SettingIcon,
  FocusIcon,
  BreakIcon,
  SettingDarkThemeIcon,
  FocusDarkThemeIcon,
  BreakDarkThemeIcon,
  HamburgerMenuIcon,
  HamburgerMenuDarkThemeIcon,
  FocusKeeperTextDarkThemeIcon,
} from '../assets';

export const theme: { [key in ThemeType]: Theme } = {
  [ThemeType.light]: {
    colors: {
      backgroundColor: '#e9ecf3',
      baseColor: '#222222',
      header: {
        nameColor: '#222222',
        loginNameColor: '#fa3338',
        profileNameColor: '#000000',
        hoverColor: '#dce0e8',
      },
      sessionList: {
        backgroundColor: '#f3f4f8',
        button: {
          fontColor: '#000000',
          selected: {
            fontColor: '#ffffff',
            backgroundColor: '#000000',
          },
        },
      },
      info: {
        fontColor: '#000000',
      },
    },
    icons: {
      header: {
        logoTextIcon: FocusKeeperTextIcon,
        settingIcon: SettingIcon,
        hamburgerIcon: HamburgerMenuIcon,
      },
      sessionList: {
        focusIcon: FocusIcon,
        breakIcon: BreakIcon,
      },
    },
  },
  [ThemeType.dark]: {
    colors: {
      backgroundColor: '#363739',
      baseColor: '#111111',
      header: {
        nameColor: '#ffffff',
        loginNameColor: '#ff7575',
        profileNameColor: '#000000',
        hoverColor: '#282828',
      },
      sessionList: {
        backgroundColor: '#202122',
        button: {
          fontColor: '#cccccc',
          selected: {
            fontColor: '#000000',
            backgroundColor: '#f1f1f1',
          },
        },
      },
      info: {
        fontColor: '#ffffff',
      },
    },
    icons: {
      header: {
        logoTextIcon: FocusKeeperTextDarkThemeIcon,
        settingIcon: SettingDarkThemeIcon,
        hamburgerIcon: HamburgerMenuDarkThemeIcon,
      },
      sessionList: {
        focusIcon: FocusDarkThemeIcon,
        breakIcon: BreakDarkThemeIcon,
      },
    },
  },
};
