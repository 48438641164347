import { ReviewCard } from '../ReviewCard/ReviewCard';
import { styled } from '@mui/material';
import { Reviews } from '../../constants/Reviews';
import { CSSProperties } from 'react';
import Marquee from 'react-fast-marquee';

interface ReviewCardListProps {
  className?: string;
  style?: CSSProperties;
}

export const ReviewCardList = styled(({ className, style }: ReviewCardListProps) => {
  return (
    <Marquee pauseOnHover className={className} style={style}>
      {Reviews.map(({ title, content, author }, key) => {
        return (
          <div key={key}>
            <StyledReviewCard rating={5} title={title} content={content} author={author}></StyledReviewCard>
          </div>
        );
      })}
    </Marquee>
  );
})`
  .rfm-marquee,
  .rfm-initial-child-container {
    align-items: flex-start;
  }
  .rfm-marquee {
    min-width: 0;
  }
`;

const StyledReviewCard = styled(ReviewCard)`
  width: 320px;
  margin-right: 16px;
`;
