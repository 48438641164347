import { styled } from '@mui/material';
import { CSSProperties } from 'react';
import { LandingQRCodeImage } from '../../assets';

interface AppDownloadQRCodeProps {
  className?: string;
  style?: CSSProperties;
}

export const AppDownloadQRCode = styled(({ className, style }: AppDownloadQRCodeProps) => {
  return (
    <div className={className} style={style}>
      <img src={LandingQRCodeImage} alt='App Download'></img>
    </div>
  );
})`
  width: 148px;
  height: 148px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  img {
    width: 100%;
    height: 100%;
  }
`;
