import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AudioInfo, SliceType, UpdateAudioInfosPayload } from '../../types';
import { defaultAudioSetting } from '../../utils/utils';
import { RootState } from '../store';

export interface AudioState {
  alarmAudioInfo: AudioInfo;
  tickingAudioInfo: AudioInfo;
}

const initialState = {
  alarmAudioInfo: defaultAudioSetting.alarm,
  tickingAudioInfo: defaultAudioSetting.ticking,
} as AudioState;

export const audioSlice = createSlice({
  name: SliceType.audio,
  initialState,
  reducers: {
    updateAlarmAudioInfo: (state, action: PayloadAction<Partial<AudioInfo>>) => {
      state.alarmAudioInfo = { ...state.alarmAudioInfo, ...action.payload };
    },
    updateTickingAudioInfo: (state, action: PayloadAction<Partial<AudioInfo>>) => {
      state.tickingAudioInfo = { ...state.tickingAudioInfo, ...action.payload };
    },
    updateAudioInfos: (state, action: PayloadAction<UpdateAudioInfosPayload>) => {
      const { alarm, ticking } = action.payload;
      state.alarmAudioInfo = { ...state.alarmAudioInfo, ...alarm };
      state.tickingAudioInfo = { ...state.tickingAudioInfo, ...ticking };
    },
  },
});

export const { updateAlarmAudioInfo, updateTickingAudioInfo, updateAudioInfos } = audioSlice.actions;

export const selectAudioInfos = createSelector(
  (state: RootState) => state.audio,
  (audioState) => {
    const { alarmAudioInfo, tickingAudioInfo } = audioState;
    return { alarmAudioInfo, tickingAudioInfo };
  },
);

export default audioSlice.reducer;
