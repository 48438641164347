import { CSSProperties, PropsWithChildren, useCallback } from 'react';
import { useAppSelector } from '../../redux/store';
import { selectTimerSetting } from '../../redux/timer/timerSlice';
import { useTimerContext } from '../../context/TimerContext';

interface TimerTriggerProps extends PropsWithChildren {
  className?: string;
  style?: CSSProperties;
  as?: keyof JSX.IntrinsicElements;
}

export const TimerControlTrigger = ({ className, style, as = 'div', children }: TimerTriggerProps) => {
  const { ticking } = useAppSelector(selectTimerSetting);
  const { sessionType } = useAppSelector(selectTimerSetting);
  const { startTimer, pauseTimer } = useTimerContext();
  const Component = as;

  const handleClick = useCallback(() => {
    ticking ? pauseTimer() : startTimer(sessionType);
  }, [startTimer, pauseTimer, sessionType, ticking]);

  return (
    <Component className={className} style={style} role='button' onClick={handleClick}>
      {children}
    </Component>
  );
};
