import { createContext, ReactNode } from 'react';
import { useSetting } from '../hooks/useSetting';
import { defaultSetting } from '../utils/utils';

export interface SettingContextState extends ReturnType<typeof useSetting> {}

export const settingContextDefaultValue: SettingContextState = {
  currentSetting: defaultSetting,
  dbSetting: {
    setting: null,
    initializeSetting: async () => {
      return { _id: '', user_id: '', created_at: new Date(), updated_at: new Date(), ...defaultSetting };
    },
  },
  localSetting: {
    setting: null,
    initializeSetting: async () => {
      return defaultSetting;
    },
  },
  updateSettingToStore: () => {},
};

export const SettingContext = createContext<SettingContextState>(settingContextDefaultValue);

export interface PropsType {
  children?: ReactNode;
}

export const SettingContextProvider = (props: PropsType) => {
  const setting = useSetting();

  return <SettingContext.Provider value={setting}>{props.children}</SettingContext.Provider>;
};
