import { styled, Typography } from '@mui/material';
import { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import { TomatoRating } from '../TomatoRating';

interface ReviewCardProps {
  className?: string;
  style?: CSSProperties;
  rating?: number;
  title: string;
  content: ReactNode;
  author: string;
}

export const ReviewCard = ({ className, style, rating = 5, title, content, author }: ReviewCardProps) => {
  return (
    <Wrapper className={className} style={style}>
      <RatingWrapper>
        <TomatoRating rating={rating}></TomatoRating>
      </RatingWrapper>
      <Title>{title}</Title>
      <Content>{content}</Content>
      <Author>by {author}</Author>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  padding: 24px;
  background-color: #fff3e2;
  border-radius: 16px;
`;

const RatingWrapper = styled('div')`
  margin-bottom: 20px;
`;

interface TitleProps extends PropsWithChildren {
  className?: string;
  style?: CSSProperties;
}

const Title = styled(({ className, style, children }: TitleProps) => {
  return (
    <Typography component={'h6'} className={className} style={style}>
      {children}
    </Typography>
  );
})`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
  margin-bottom: 8px;
  color: #000000c2;
`;

interface ContentProps extends PropsWithChildren {
  className?: string;
  style?: CSSProperties;
}

const Content = styled(({ className, style, children }: ContentProps) => {
  return (
    <Typography component={'p'} className={className} style={style}>
      {children}
    </Typography>
  );
})`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  margin-bottom: 16px;
  color: #000000c2;
`;

interface AuthorProps extends PropsWithChildren {
  className?: string;
  style?: CSSProperties;
}

const Author = styled(({ className, style, children }: AuthorProps) => {
  return (
    <Typography component={'p'} className={className} style={style}>
      {children}
    </Typography>
  );
})`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  color: #0000008a;
`;
