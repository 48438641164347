import { styled, Typography } from '@mui/material';
import { FocusLineSvg } from '../../assets';
import { CSSProperties, PropsWithChildren } from 'react';
import { Description } from './Description';
import { SinglePathSVGAnimation } from '../SinglePathSVGAnimation';

interface TotalScoreProps {
  inView?: boolean;
}

export const TotalScore = ({ inView }: TotalScoreProps) => {
  return (
    <Wrapper>
      <TotalScoreWrapper>
        <SinglePathSVGAnimation
          active={inView}
          delay={0.6}
          src={FocusLineSvg}
          width={160}
          height={160}
        ></SinglePathSVGAnimation>
        <Score>4.8</Score>
      </TotalScoreWrapper>
      <Description></Description>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
`;

const TotalScoreWrapper = styled('div')`
  display: inline-block;
  position: relative;
  margin: auto;
`;

interface ScoreProps extends PropsWithChildren {
  className?: string;
  style?: CSSProperties;
}

const Score = styled(({ className, children, style }: ScoreProps) => {
  return (
    <Typography className={className} style={style}>
      {children}
    </Typography>
  );
})`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 96px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 105.6px */
  color: white;
`;
