import { styled } from '@mui/material';
import Wave from 'react-wavify';
import { SessionType } from '../../types';
import { CSSProperties } from 'react';
import { clsx } from 'clsx';
import { LONG_BREAK_COLOR, SHORT_BREAK_COLOR } from '../../constants/Colors';

interface TimerWaveProps {
  className?: string;
  style?: CSSProperties;
  ticking?: boolean;
  value: number;
  session: SessionType;
}

const MAX_VALUE = 1;
const MIN_VALUE = 0;

export const TimerWave = ({ className, style, ticking = false, value, session }: TimerWaveProps) => {
  const safeValue = value > MAX_VALUE ? MAX_VALUE : value < MIN_VALUE ? MIN_VALUE : value;
  const heightRatio = safeValue * 100;
  const isActiveWaveTransition = !ticking;

  let fillColor = '#FF5C5C';
  if (session === SessionType.longBreak) fillColor = LONG_BREAK_COLOR;
  if (session === SessionType.shortBreak) fillColor = SHORT_BREAK_COLOR;

  let height = `${heightRatio}%`;
  if (session !== SessionType.focus) height = `${100 - heightRatio}%`;

  return (
    <TimerWaveWrapper className={clsx(className, 'TimerWaveWrapper')} style={style}>
      <WaveWrapper height={height} transition={isActiveWaveTransition}>
        <StyledWave
          className='Wave'
          fill={fillColor}
          style={{
            height: '100%',
          }}
          options={{
            height: 15,
            speed: 0.4,
            points: 5,
            amplitude: 7,
          }}
        ></StyledWave>
        <SafeArea backgroundColor={fillColor}></SafeArea>
      </WaveWrapper>
    </TimerWaveWrapper>
  );
};

const TimerWaveWrapper = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: flex-end;
`;

interface WaveWrapperProps {
  height: string;
  transition: boolean;
}

const WaveWrapper = styled('div')<WaveWrapperProps>`
  height: ${({ height }) => height};
  position: relative;
  width: 100%;
  min-height: 40px;
  ${({ transition }) => (transition ? 'transition: 2s height;' : undefined)}
`;

const StyledWave = styled(Wave)`
  min-height: 40px;
  path {
    transition: var(--background-transition-duration) fill;
  }
`;

interface SafeAreaProps {
  backgroundColor: string;
}

const SafeArea = styled('div')<SafeAreaProps>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  transition-property: background-color, height;
  transition-duration: var(--background-transition-duration), 2s;
  background-color: ${({ backgroundColor }) => `${backgroundColor}`};
`;
