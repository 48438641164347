import { Howl } from 'howler';
import { useCallback, useState } from 'react';
import { noneHowl } from '../assets';

export const useAudio = () => {
  const [id, setId] = useState<number>(0);
  const [audio, setAudio] = useState<Howl>(noneHowl);
  const [loading, setLoading] = useState<boolean>(false);

  const changeId = useCallback((id: number) => {
    setId(id);
  }, []);

  const changeAudio = useCallback(
    (howl: Howl) => {
      audio.stop().unload();

      if (howl.state() === 'unloaded') {
        setLoading(true);
        howl.once('load', () => setLoading(false)).load();
        setAudio(howl);
        return;
      }

      if (howl.state() === 'loading') {
        setLoading(true);
        howl.once('load', () => setLoading(false));
        setAudio(howl);
        return;
      }

      setAudio(howl);
    },
    [audio],
  );

  const changeLoading = useCallback((loading: boolean) => {
    setLoading(loading);
  }, []);

  return { id, audio, loading, setId, setAudio, setLoading, changeId, changeAudio, changeLoading } as const;
};
