import { setUserId as ampSetUserId } from '@amplitude/analytics-browser';
import { Howl } from 'howler';
import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import type { User as RealmUser } from 'realm-web';
import { selectChartsFromDB } from '../../api/api';
import { UserSettingLocal } from '../../api/interface';
import { AudioContext } from '../../context/AudioContext';
import { SettingContext } from '../../context/SettingContext';
import { useLog } from '../../hooks/useLog';
import { setCharts } from '../../redux/charts/chartsSlice';
import { selectSetting } from '../../redux/setting/settingSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setSessionCount } from '../../redux/timer/timerSlice';
import { selectUser, setUserId } from '../../redux/user/userSlice';
import {
  combineChartsByDate,
  convertLocalToGeneral,
  getAlarmSound,
  getTickingSound,
  getTodayChart,
  loadChartsFromLocal,
} from '../../utils/utils';
import { Header } from '../Header/Header';
import { Pomodoro } from '../Pomodoro/Pomodoro';
import { SEO } from '../SEO/SEO';
import { styled } from '@mui/material';
import { PomodoroLoadingIndicator } from '../PomodoroLoadingIndicator/PomodoroLoadingIndicator';
import { Partners } from '../Partners/Partners';
import { ServiceIntro } from '../ServiceIntro';
import { ServiceGuide } from '../ServiceGuide';
import { Impact } from '../Impact';
import { Reviews } from '../Reviews';
import { AppDownload } from '../AppDownload';
import { Footer } from '../Footer';

// TODO: 고도화 필요 - streak 계산하기 위해 30일 단위로 받아오고 있지만 30일이 넘어가는 경우에 대응해야함
const selectDataAboutMonth = (user: RealmUser) => {
  const today = new Date();
  const monthAgoFromToday = new Date();

  monthAgoFromToday.setDate(today.getDate() - 30);

  return selectChartsFromDB(user, monthAgoFromToday, today);
};

export const Main = () => {
  const audioState = useContext(AudioContext);
  const dispatch = useAppDispatch();
  const log = useLog();
  const user = useAppSelector(selectUser);
  const longBreakInterval = useAppSelector(selectSetting).longBreakInterval;
  const { dbSetting, localSetting, updateSettingToStore } = useContext(SettingContext);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const initializeAudios = (setting: UserSettingLocal) => {
      const alarmHowl = new Howl({
        src: getAlarmSound(setting.alarm_sound).src,
        volume: setting.alarm_volume / 100,
        html5: true,
      });
      const tickingHowl = new Howl({
        src: getTickingSound(setting.ticking_sound).src,
        volume: setting.ticking_volume / 100,
        loop: true,
        html5: true,
      });

      audioState.alarmAudioState.changeAudio(alarmHowl);
      audioState.tickingAudioState.changeAudio(tickingHowl);
    };

    const initializeSettingAndChart = async (user: RealmUser) => {
      const [setting, charts] = await Promise.all([dbSetting.initializeSetting(user), selectDataAboutMonth(user)]);

      ampSetUserId(user.id);
      initializeAudios(setting);
      updateSettingToStore(setting);
      dispatch(setCharts(charts));
      dispatch(setSessionCount(getTodayChart(combineChartsByDate(charts)).goal_count % longBreakInterval));
    };

    const initializeUser = async (user: RealmUser | null) => {
      if (user) {
        setLoading(true);
        try {
          await initializeSettingAndChart(user);
          dispatch(setUserId(user.id));
        } catch (error: any) {
          log.error(error.error);
          dispatch(setUserId(null));
        }

        setLoading(false);
      } else {
        const setting = await localSetting.initializeSetting();
        const charts = convertLocalToGeneral(loadChartsFromLocal());

        ampSetUserId(undefined);
        initializeAudios(setting);
        updateSettingToStore(setting);
        dispatch(setCharts(charts));
        dispatch(setSessionCount(getTodayChart(combineChartsByDate(charts)).goal_count % longBreakInterval));
        setTimeout(() => setLoading(false), 1500);
      }
    };

    initializeUser(user);
  }, [user]);

  return (
    <>
      <SEO />
      <Outlet />
      <MainWrapper>
        {loading && <StyledPomodoroLoadingIndicator></StyledPomodoroLoadingIndicator>}
        <StyledHeader />
        <Pomodoro />
      </MainWrapper>
      <Partners></Partners>
      <ServiceIntro></ServiceIntro>
      <ServiceGuide></ServiceGuide>
      <Impact></Impact>
      <Reviews></Reviews>
      <AppDownload></AppDownload>
      <Footer></Footer>
    </>
  );
};

const StyledPomodoroLoadingIndicator = styled(PomodoroLoadingIndicator)`
  z-index: 2;
`;

const StyledHeader = styled(Header)`
  position: relative;
  z-index: 1;
`;

const MainWrapper = styled('main')`
  display: flex;
  flex-direction: column;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: background-color 300ms;
  background-color: #fff3e2;
  overflow: hidden;
`;
