import { useState } from 'react';
import type { User as RealmUser } from 'realm-web';
import { insertSettingFromDB, selectSettingFromDB } from '../api/api';
import { UserSetting } from '../api/interface';
import { loadSettingFromLocal } from '../utils/utils';

export const useDBSetting = () => {
  const [setting, setSetting] = useState<UserSetting | null>(null);

  const selectSetting = async (user: RealmUser) => {
    return new Promise<UserSetting>(async (resolve, reject) => {
      const setting = await selectSettingFromDB(user);

      return setting ? resolve(setting) : reject();
    });
  };

  const initializeSetting = async (user: RealmUser): Promise<UserSetting> => {
    try {
      const setting = await selectSetting(user);
      setSetting(setting);
      return setting;
    } catch {
      const localSetting = await loadSettingFromLocal();
      const result = await insertSettingFromDB(user, localSetting);
      const setting: UserSetting = {
        ...localSetting,
        _id: result.insertedId,
        user_id: user.id,
        created_at: new Date(),
        updated_at: new Date(),
      };
      setSetting(setting);
      return setting;
    }
  };

  return { setting, initializeSetting } as const;
};
