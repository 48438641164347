import { v4 as uuid } from 'uuid';
import { insertChartFromDB, insertChartsFromDB } from '../api/api';
import { UserChartLocal } from '../api/interface';
import { insertChart, insertCharts } from '../redux/charts/chartsSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { selectUser } from '../redux/user/userSlice';
import { insertChartsFromLocal } from '../utils/utils';

export const useUpdateChartData = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const onChangeChartData = async (chart: UserChartLocal) => {
    const date = new Date();

    if (user) {
      const { insertedId } = await insertChartFromDB(user, chart);

      dispatch(insertChart({ ...chart, _id: insertedId, user_id: user.id, created_at: date, updated_at: date }));
    } else {
      insertChartsFromLocal([chart]);
      dispatch(insertChart({ ...chart, _id: uuid(), user_id: '', created_at: date, updated_at: date }));
    }
  };

  const onChangeChartsData = async (charts: UserChartLocal[]) => {
    const date = new Date();

    if (user) {
      const { insertedIds } = await insertChartsFromDB(user, charts);

      dispatch(
        insertCharts(
          insertedIds.map((insertedId, i) => ({
            ...charts[i],
            _id: insertedId,
            user_id: user.id,
            created_at: date,
            updated_at: date,
          })),
        ),
      );
    } else {
      insertChartsFromLocal(charts);
      dispatch(
        insertCharts(
          charts.map((chart) => ({
            ...chart,
            _id: uuid(),
            user_id: '',
            created_at: date,
            updated_at: date,
          })),
        ),
      );
    }
  };

  return { onChangeChartData, onChangeChartsData } as const;
};
