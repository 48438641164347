import { Grid, styled, Typography } from '@mui/material';
import { CSSProperties, PropsWithChildren, useRef } from 'react';
import { LottieImage } from '../LottieImage';
import { useInView, motion } from 'framer-motion';
import { TransitionVariants } from '../../constants/Transition';

interface ImpactItemProps {
  title: string;
  content: string;
  lottie: { [key: string]: any };
}

export const ImpactItem = ({ title, content, lottie }: ImpactItemProps) => {
  const rootEl = useRef<HTMLDivElement>(null);

  const isInView = useInView(rootEl, { once: true, amount: 0.5 });

  return (
    <div className='ImpactItem' ref={rootEl}>
      <Grid container alignItems={'center'}>
        <Grid item lg={2} md={8} xs={4}>
          <ImageWrapper
            variants={TransitionVariants}
            initial={'fadeOut'}
            animate={isInView && 'fadeIn'}
            transition={{
              duration: 0.4,
            }}
          >
            <StyledLottie options={{ animationData: lottie }}></StyledLottie>
          </ImageWrapper>
        </Grid>
        <Grid item lg={4} md={8} xs={4}>
          <motion.div
            variants={TransitionVariants}
            initial={'fadeOut'}
            animate={isInView && 'fadeIn'}
            transition={{
              duration: 0.4,
              delay: 0.1,
            }}
          >
            <Title>{title}</Title>
          </motion.div>
        </Grid>
        <Grid item lg={5} md={8} xs={4}>
          <motion.div
            variants={TransitionVariants}
            initial={'fadeOut'}
            animate={isInView && 'fadeIn'}
            transition={{
              duration: 0.4,
              delay: 0.2,
            }}
          >
            <Content>{content}</Content>
          </motion.div>
        </Grid>
      </Grid>
    </div>
  );
};

const ImageWrapper = styled(motion.div)`
  width: 96px;
  height: 96px;
  transition: var(--background-transition-duration) background-color;
  background-color: var(--current-session-color);
  ${({ theme }) => `${theme.breakpoints.down('lg')} {
    display: flex;
    margin: auto auto 32px;
    align-items: center;
    justify-content: center;
  }`}
`;

const StyledLottie = styled(LottieImage)`
  mix-blend-mode: screen;
`;

interface TitleProps extends PropsWithChildren {
  className?: string;
  style?: CSSProperties;
}

const Title = styled(({ children, className, style }: TitleProps) => {
  return (
    <Typography className={className} style={style} component={'h4'}>
      {children}
    </Typography>
  );
})`
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 67.2px */
  color: white;
  ${({ theme }) => `${theme.breakpoints.down('lg')} {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 46.8px */
    text-align: center;
  }`}
`;

interface ContentProps extends PropsWithChildren {
  className?: string;
  style?: CSSProperties;
}

const Content = styled(({ className, style, children }: ContentProps) => {
  return (
    <Typography component={'p'} className={className} style={style}>
      {children}
    </Typography>
  );
})`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  color: white;
  ${({ theme }) => `${theme.breakpoints.down('lg')} {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    text-align: center;
    margin-top: 16px;
  }`}
`;
