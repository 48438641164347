import CloseIcon from '@mui/icons-material/Close';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router';
import { Paths } from '../../types';

interface DialogProps extends MuiDialogProps {
  isDisplayTitle?: boolean;
  onClickCloseIcon?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const Dialog = (props: DialogProps) => {
  const navigate = useNavigate();

  const onClose = () => {
    navigate(Paths.home);
  };

  const renderTitle = () => {
    if (props.isDisplayTitle === false) {
      return null;
    }

    return (
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography fontSize={26} fontWeight='600' textTransform='capitalize'>
          {props.title}
        </Typography>
        <IconButton onClick={props.onClickCloseIcon ? props.onClickCloseIcon : onClose} color='inherit'>
          <CloseIcon fontSize='large' />
        </IconButton>
      </DialogTitle>
    );
  };

  return (
    <MuiDialog
      keepMounted={true}
      scroll='body'
      PaperProps={{ sx: { borderRadius: 4, boxShadow: 'none' } }}
      TransitionProps={{ style: { height: 'auto' } }}
      {...props}
      onClose={(...onCloseProps) => {
        props.onClose ? props.onClose(...onCloseProps) : onClose();
      }}
    >
      {renderTitle()}
      <DialogContent>{props.children}</DialogContent>
    </MuiDialog>
  );
};
