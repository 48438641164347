import { styled } from '@mui/material';
import InlineSVG from 'react-inlinesvg';
import { TomatoRankingIcon } from '../../assets';

interface TomatoRatingProps {
  rating?: number;
}

export const TomatoRating = ({ rating = 5 }: TomatoRatingProps) => {
  return (
    <List>
      {Array(rating)
        .fill('')
        .map((_, index) => {
          return (
            <ListItem key={index}>
              <InlineSVG src={TomatoRankingIcon} width={20} height={20}></InlineSVG>
            </ListItem>
          );
        })}
    </List>
  );
};

const List = styled('ul')`
  display: flex;
  align-items: center;
`;

const ListItem = styled('li')`
  margin-right: 4px;
  &:last-of-type {
    margin-right: 0;
  }
`;
