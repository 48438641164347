import { useSnackbar, OptionsObject } from 'notistack';

export const useLog = () => {
  const { enqueueSnackbar } = useSnackbar();

  return {
    info: (m: string, options?: OptionsObject) => {
      enqueueSnackbar(m, { variant: 'info', ...options });
    },
    success: (m: string, options?: OptionsObject) => {
      enqueueSnackbar(m, { variant: 'success', ...options });
    },
    error: (m: string, options?: OptionsObject) => {
      enqueueSnackbar(m, { variant: 'error', ...options });
    },
  };
};
