import { ReactNode, useCallback, useContext } from 'react';
import { SessionType } from '../../types';
import { useAppSelector } from '../../redux/store';
import { selectTimerInfos, selectTimerSetting } from '../../redux/timer/timerSlice';
import { AudioContext } from '../../context/AudioContext';
import { useTimerContext } from '../../context/TimerContext';

interface ChildrenArguments {
  isMute: boolean;
}

interface MuteTickingSoundTriggerProps {
  as?: keyof JSX.IntrinsicElements;
  children?: (args: ChildrenArguments) => ReactNode;
}

export const MuteTickingSoundTrigger = ({ as = 'div', children }: MuteTickingSoundTriggerProps) => {
  const { sessionType } = useAppSelector(selectTimerSetting);
  const {
    focus: focusSettings,
    shortBreak: shortBreakSettings,
    longBreak: longBreakSettings,
  } = useAppSelector(selectTimerInfos);
  const { mute, unmute } = useTimerContext();

  const Component = as;

  const { mute: focusMute } = focusSettings;
  const { mute: shortBreakMute } = shortBreakSettings;
  const { mute: longBreakMute } = longBreakSettings;
  let currentMute = focusMute;
  if (sessionType === SessionType.shortBreak) currentMute = shortBreakMute;
  if (sessionType === SessionType.longBreak) currentMute = longBreakMute;

  const handleClick = useCallback(() => {
    currentMute ? unmute(sessionType) : mute(sessionType);
  }, [mute, unmute, currentMute, sessionType]);

  return <Component onClick={handleClick}>{children?.({ isMute: currentMute })}</Component>;
};
