import { useState } from 'react';
import { UserSettingLocal } from '../api/interface';
import { defaultSetting, loadSettingFromLocal, saveSettingInLocal } from '../utils/utils';

export const useLocalSetting = () => {
  const [setting, setSetting] = useState<UserSettingLocal | null>(null);

  const initializeSetting = async () => {
    try {
      const setting = await loadSettingFromLocal();
      setSetting(setting);
      return setting;
    } catch {
      saveSettingInLocal(defaultSetting);
      setSetting(defaultSetting);
      return defaultSetting;
    }
  };

  return { setting, initializeSetting } as const;
};
