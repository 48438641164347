import styled from '@emotion/styled';
import { SessionType } from '../../types';
import { CSSProperties } from 'react';
import { Typography } from '@mui/material';

interface PomodoroTypeLabelProps {
  className?: string;
  style?: CSSProperties;
  value: SessionType;
}

export const PomodoroTypeLabel = styled(({ className, style, value }: PomodoroTypeLabelProps) => {
  let label = 'Focus Timer';
  if (value === SessionType.shortBreak) label = 'Short Break Timer';
  if (value === SessionType.longBreak) label = 'Long Break Timer';

  return (
    <Typography className={className} style={style} component={'p'}>
      {label}
    </Typography>
  );
})`
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: #0000005e;
`;
