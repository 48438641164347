import { v4 as uuid } from 'uuid';
import { UserChart, UserChartByDate, UserChartLocal, UserSettingLocal } from '../api/interface';
import { alarmSounds, tickingSounds } from '../assets';
import { en, ko } from '../lang';
import {
  AudioSetting,
  AudioType,
  ChartOverviewDate,
  ChartType,
  LocalSound,
  Metadata,
  ModalType,
  NotificationType,
  Paths,
  SessionType,
  TimerColorType,
  TimerSetting,
  UsablePath,
} from '../types';

export const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY as string;
export const LANG = process.env.REACT_APP_LANG;
export const DEFAULT_LANG = 'en';
export const FOCUSKEEPER_ID = 'focuskeeper';
export const NOTIFICATION_ID = 'notification';
export const CHART_KEY = 'report';
export const SETTING_KEY = 'setting';
export const MAX_MINUTE_VALUE = 360;
export const AUTO_START_DELAY_IN_MILLISECOND = 500;
export const MAX_LONG_BREAK_INTERVAL_VALUE = 8;
export const GOAL_PER_DAY = 4;

const metadataOfLang = { en, ko };
const defaultSessionSeconds = {
  [SessionType.focus]: 25 * 60,
  [SessionType.shortBreak]: 5 * 60,
  [SessionType.longBreak]: 30 * 60,
};
export const defaultTimerSetting: TimerSetting = {
  [SessionType.focus]: {
    sessionType: SessionType.focus,
    second: defaultSessionSeconds.focus,
    color: TimerColorType.focus,
    mute: false,
  },
  [SessionType.shortBreak]: {
    sessionType: SessionType.shortBreak,
    second: defaultSessionSeconds.shortBreak,
    color: TimerColorType.shortBreak,
    mute: false,
  },
  [SessionType.longBreak]: {
    sessionType: SessionType.longBreak,
    second: defaultSessionSeconds.longBreak,
    color: TimerColorType.longBreak,
    mute: false,
  },
};
export const defaultAudioSetting: AudioSetting = {
  [AudioType.alarm]: {
    audioType: AudioType.alarm,
    sound: alarmSounds[0].name,
    volume: 50,
    repeat: 1,
  },
  [AudioType.ticking]: {
    audioType: AudioType.ticking,
    sound: tickingSounds[0].name,
    volume: 50,
  },
};
export const defaultSetting: UserSettingLocal = {
  focus_duration: defaultSessionSeconds.focus,
  short_break_duration: defaultSessionSeconds.shortBreak,
  long_break_duration: defaultSessionSeconds.longBreak,
  autostart_pomodoro: false,
  autostart_break: false,
  notification_type: NotificationType.last,
  notification_minutes: 5,
  dark_mode: false,
  alarm_sound: alarmSounds[0].name,
  alarm_volume: 50,
  alarm_repeat: 1,
  ticking_sound: tickingSounds[0].name,
  ticking_volume: 50,
  long_break_interval: 4,
};
export const defaultChart: UserChartLocal = {
  date: new Date(),
  focus_duration: 0,
  break_duration: 0,
};

export function classNames(...strings: string[]): string {
  return strings.filter((v) => v).join(' ');
}

// ? about environment

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
}

export function isSafariIOS() {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

  return iOSSafari;
}

// ? about converting

export function pad(n: number) {
  return n < 0 ? '00' : Math.floor(n).toString().padStart(2, '0');
}

export function convertSecondToTimerCountdownNumber(second: number) {
  const MINUTE_IN_A_SECOND = 60;
  const min = pad(second / MINUTE_IN_A_SECOND);
  const sec = pad(second % MINUTE_IN_A_SECOND);

  return `${min}:${sec}`;
}

export function convertSecondToChartLocaleString(second: number) {
  if (second < 0) return '0m';

  const dateString = new Date(second * 1000).toISOString();
  const day = parseInt(dateString.slice(8, 10)) - 1;
  const hour = parseInt(dateString.slice(11, 13));
  const min = parseInt(dateString.slice(14, 16));

  return hour + day * 24 === 0 ? `${min}m` : `${hour + day * 24}h ${min}m`;
}

export function convertSecondToHourInDay(second: number): number {
  return second < 0 ? 0 : parseInt(new Date(second * 1000).toISOString().slice(11, 13));
}

export function convertLocaleString(date: Date) {
  return date.toISOString().slice(0, 10);
}

export function convertChartDateToChartTitle(date: Date, overviewType: ChartType) {
  const compareDate = new Date();

  if (overviewType === ChartType.monthly) {
    const monthAgo = new Date(date);
    monthAgo.setDate(monthAgo.getDate() - ChartOverviewDate[overviewType]);

    return `${getWeekDay(monthAgo)} ${monthAgo.getFullYear()} - ${getWeekDay(date)} ${date.getFullYear()} `;
  }

  if (convertLocaleString(date) === convertLocaleString(compareDate)) {
    return 'Today';
  }

  compareDate.setDate(compareDate.getDate() - 1);

  if (convertLocaleString(date) === convertLocaleString(compareDate)) {
    return 'Yesterday';
  }

  return date.toDateString().slice(4);
}

export function convertCamelToUpper(str: string) {
  const upper = str.replace(/([A-Z])/g, ' $1');

  return upper.charAt(0).toUpperCase() + upper.slice(1);
}

export function convertCamelToSnake(str: string) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function getWeekDay(date: Date) {
  const language = 'en-us';
  const weekDay = date.toLocaleString(language, {
    month: 'short',
  });

  return weekDay;
}

export function getDiffDays(date1: Date, date2: Date) {
  const d1 = new Date(convertLocaleString(date1));
  const d2 = new Date(convertLocaleString(date2));
  const diffTime = Math.abs(d1.getTime() - d2.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
}

export function isValidateEmail(email: string) {
  return !!email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
}

export function getModalPathPrefix(modalType: ModalType) {
  return `${Paths.modal}/${modalType}`;
}

export function combine(values: string[], separator: string = '_') {
  return values.filter((v) => v).join(separator);
}

// ? about audio

export function getSound(soundList: LocalSound[], name: string) {
  return soundList.find(({ name: _name }) => _name === name)!;
}

export function getAlarmSound(name: string) {
  return getSound(alarmSounds, name);
}

export function getTickingSound(name: string) {
  return getSound(tickingSounds, name);
}

// ? about setting

export function loadSettingFromLocal() {
  return new Promise<UserSettingLocal>((resolve, reject) => {
    const settingFromLocal = localStorage.getItem(SETTING_KEY);

    return settingFromLocal ? resolve(JSON.parse(settingFromLocal)) : reject();
  });
}

export function saveSettingInLocal(setting: UserSettingLocal) {
  localStorage.setItem(SETTING_KEY, JSON.stringify(setting));
}

export function saveNewSettingInLocal(setting: Partial<UserSettingLocal>) {
  const prevSetting = JSON.parse(localStorage.getItem(SETTING_KEY) as string) as UserSettingLocal;

  saveSettingInLocal({ ...prevSetting, ...setting });
}

// ? about Chart

export function loadChartsFromLocal(): UserChartLocal[] {
  const charts = localStorage.getItem(CHART_KEY);

  if (charts) {
    const _charts = JSON.parse(charts) as UserChartLocal[];

    return _charts.map((chart) => ({ ...chart, date: new Date(chart.date) }));
  } else {
    return [];
  }
}

export function insertChartsFromLocal(newCharts: UserChartLocal[]) {
  const charts = loadChartsFromLocal();

  charts.push(...newCharts);
  localStorage.setItem(CHART_KEY, JSON.stringify(charts));
}

export function getChartAtDate(charts: UserChartLocal[], _date: Date = new Date()) {
  const chartsAtDate = charts.find(({ date }) => convertLocaleString(date) === convertLocaleString(_date));

  return chartsAtDate ? chartsAtDate : null;
}

export const convertLocalToGeneral = (charts: UserChartLocal[]): UserChart[] => {
  return charts.map((chart, id) => ({
    ...chart,
    _id: uuid(),
    user_id: '',
    created_at: chart.date,
    updated_at: chart.date,
  }));
};

export function combineChartsByDate(charts: UserChart[]): UserChartByDate[] {
  return Array.from(
    charts
      .reduce((map, chart) => {
        const key = convertLocaleString(chart.date);
        const prevChart = map.get(key);
        const newChart: UserChartByDate = { ...chart, goal_count: prevChart?.goal_count || 0 };

        if (newChart.focus_duration > 0) {
          newChart.goal_count += 1;
        }

        if (prevChart) {
          newChart.focus_duration += prevChart.focus_duration;
          newChart.break_duration += prevChart.break_duration;
        }

        return map.set(key, { ...newChart });
      }, new Map<string, UserChartByDate>())
      .values(),
  );
}

export function getTodayChart(charts: UserChartByDate[]): UserChartByDate {
  const todayChart = charts.find(({ date }) => convertLocaleString(date) === convertLocaleString(new Date()));
  const defaultTodayChart = { ...defaultChart, goal_count: 0 } as UserChartByDate;

  return todayChart || defaultTodayChart;
}

// ? translate

export const getMetadataOfLangByPage = (lang: string, page?: UsablePath): Metadata => {
  if (lang in metadataOfLang) {
    if (page) {
      return metadataOfLang[lang as keyof typeof metadataOfLang][page];
    }

    return metadataOfLang[lang as keyof typeof metadataOfLang];
  }

  if (page) {
    return metadataOfLang[DEFAULT_LANG][page];
  }

  return metadataOfLang[DEFAULT_LANG];
};
