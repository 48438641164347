import { styled } from '@mui/material';
import { CSSProperties } from 'react';
import { GuideStep } from '../../types/randing';
import { TimerStep } from './TimerStep';

interface TimerStepsProps {
  className?: string;
  style?: CSSProperties;
  step: GuideStep;
}

export const TimerSteps = styled(({ className, style, step }: TimerStepsProps) => {
  return (
    <ul className={className} style={style}>
      <li>{step === GuideStep.focus ? <TimerStep type={GuideStep.focus}></TimerStep> : <Circle></Circle>}</li>
      <li>{step === GuideStep.shortBreak ? <TimerStep type={GuideStep.shortBreak}></TimerStep> : <Circle></Circle>}</li>
      <li>{step === GuideStep.interval ? <TimerStep type={GuideStep.interval}></TimerStep> : <Circle></Circle>}</li>
      <li>{step === GuideStep.longBreak ? <TimerStep type={GuideStep.longBreak}></TimerStep> : <Circle></Circle>}</li>
    </ul>
  );
})`
  display: flex;
  align-items: center;
  column-gap: 12px;
  list-style: none;
`;

const Circle = styled('span')`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #595959;
`;
