import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SessionSecondsType, SessionType, SliceType, TimerInfo, TimerInfos } from '../../types';
import { defaultTimerSetting } from '../../utils/utils';
import { RootState } from '../store';

export interface TimerState {
  ticking: boolean;
  sessionCount: number;
  sessionType: SessionType;
  focusInfo: TimerInfo;
  shortBreakInfo: TimerInfo;
  longBreakInfo: TimerInfo;
}

const initialState = {
  ticking: false,
  sessionCount: 0,
  sessionType: SessionType.focus,
  focusInfo: defaultTimerSetting.focus,
  shortBreakInfo: defaultTimerSetting.shortBreak,
  longBreakInfo: defaultTimerSetting.longBreak,
} as TimerState;

export const timerSlice = createSlice({
  name: SliceType.timer,
  initialState,
  reducers: {
    setTicking: (state, action: PayloadAction<boolean>) => {
      state.ticking = action.payload;
    },
    setSessionCount: (state, action: PayloadAction<number>) => {
      state.sessionCount = action.payload;
    },
    setSessionType: (state, action: PayloadAction<SessionType>) => {
      state.sessionType = action.payload;
    },
    setFocusInfo: (state, action: PayloadAction<Partial<TimerInfo>>) => {
      state.focusInfo = {
        ...state.focusInfo,
        ...action.payload,
      };
    },
    setShortBreakInfo: (state, action: PayloadAction<Partial<TimerInfo>>) => {
      state.shortBreakInfo = {
        ...state.shortBreakInfo,
        ...action.payload,
      };
    },
    setLongBreakInfo: (state, action: PayloadAction<Partial<TimerInfo>>) => {
      state.longBreakInfo = {
        ...state.longBreakInfo,
        ...action.payload,
      };
    },
    updateTimerInfosSecond: (state, action: PayloadAction<SessionSecondsType>) => {
      const [focus, shortBreak, longBreak] = action.payload;
      state.focusInfo.second = focus;
      state.shortBreakInfo.second = shortBreak;
      state.longBreakInfo.second = longBreak;
    },
  },
});

export const {
  setTicking,
  setSessionCount,
  setSessionType,
  setFocusInfo,
  setShortBreakInfo,
  setLongBreakInfo,
  updateTimerInfosSecond,
} = timerSlice.actions;

export const selectTimerSetting = createSelector(
  (state: RootState) => state.timer,
  (timerState) => {
    const { sessionType, ticking, sessionCount } = timerState;
    return { sessionType, ticking, sessionCount };
  },
);

export const selectTimerInfos = createSelector(
  (state: RootState) => state.timer,
  (timerState) => {
    const { focusInfo, shortBreakInfo, longBreakInfo } = timerState;
    return { focus: focusInfo, shortBreak: shortBreakInfo, longBreak: longBreakInfo } as TimerInfos;
  },
);

export const selectCurrentTimerInfo = createSelector(
  (state: RootState) => state.timer,
  (timerState) => {
    const { sessionType, focusInfo, shortBreakInfo, longBreakInfo } = timerState;

    switch (sessionType) {
      case SessionType.focus:
        return focusInfo;
      case SessionType.shortBreak:
        return shortBreakInfo;
      case SessionType.longBreak:
        return longBreakInfo;
      default:
        return focusInfo;
    }
  },
);

export default timerSlice.reducer;
