import { useContext } from 'react';
import { TimerContext } from './TimerProvider';

export const useTimerContext = () => {
  const context = useContext(TimerContext);

  if (context === undefined) throw new Error('TimerContext is undefined.');

  return context;
};
