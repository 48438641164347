import { GlobalStyles as MuiGlobalStyles } from '@mui/material';
import {
  BACKGROUND_COLOR,
  FOCUS_COLOR,
  INTERVAL_COLOR,
  LONG_BREAK_COLOR,
  SHORT_BREAK_COLOR,
} from '../../constants/Colors';
import { useAppSelector } from '../../redux/store';
import { selectTimerSetting } from '../../redux/timer/timerSlice';
import { SessionType } from '../../types';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export const GlobalStyles = () => {
  const { sessionType } = useAppSelector(selectTimerSetting);

  return (
    <MuiGlobalStyles
      styles={`
        html {
          --background-color: ${BACKGROUND_COLOR};
          --focus-color: ${FOCUS_COLOR};
          --short-break-color: ${SHORT_BREAK_COLOR};
          --long-break-color: ${LONG_BREAK_COLOR};
          --interval-color: ${INTERVAL_COLOR};
          --background-transition-duration: 1.2s;
          ${
            sessionType === SessionType.focus
              ? `--current-session-color: ${FOCUS_COLOR};`
              : sessionType === SessionType.shortBreak
              ? `--current-session-color: ${SHORT_BREAK_COLOR};`
              : sessionType === SessionType.longBreak
              ? `--current-session-color: ${LONG_BREAK_COLOR};`
              : ''
          }
        }
        body {
          background-color: var(--background-color);
        }


        /**
         * Memo: 무슨 사유인지는 모르겠는데 어느 순간부터 Dialog에 max-width: 0; 이 적용 되는 문제가 발생함.
         * 정상적인 Dialog 표시를 위해 임시방편으로 처리함. 
         */
        .MuiDialog-container {
          .MuiPaper-root {
            max-width: 100vw;
          }
        }
      `}
    ></MuiGlobalStyles>
  );
};
