import { styled } from '@mui/material';
import { SessionButton } from '../SessionButton/SessionButton';
import { useAppSelector } from '../../redux/store';
import { selectTimerInfos, selectTimerSetting } from '../../redux/timer/timerSlice';
import { DBSettingColumns, SessionType } from '../../types';
import { useCallback } from 'react';
import { TimerControlButton } from '../TimerControlButton/TimerControlButton';
import { updateSettingFromDB } from '../../api/api';
import { selectUser } from '../../redux/user/userSlice';
import { saveNewSettingInLocal } from '../../utils/utils';
import { UserSetting } from '../../api/interface';
import { useSetting } from '../../hooks/useSetting';
import { useTimerContext } from '../../context/TimerContext';

interface BlurPayload {
  session: SessionType;
  minutes: number;
  seconds: number;
}

const secondsToMinutes = (seconds: number) => Math.floor((seconds * 10) / 60) / 10;

export const TimerController = () => {
  const user = useAppSelector(selectUser);
  const { focus, shortBreak, longBreak } = useAppSelector(selectTimerInfos);
  const { second: focusSeconds, mute: focusMute } = focus;
  const { second: shortBreakSeconds, mute: shortBreakMute } = shortBreak;
  const { second: longBreakSeconds, mute: longBreakMute } = longBreak;
  const focusMinutes = secondsToMinutes(focusSeconds);
  const shortBreakMinutes = secondsToMinutes(shortBreakSeconds);
  const longBreakMinutes = secondsToMinutes(longBreakSeconds);
  const { sessionType, ticking } = useAppSelector(selectTimerSetting);
  const { updateSettingToStore } = useSetting();
  const { changeSession } = useTimerContext();

  const handleClickButton = useCallback(
    (session: SessionType) => {
      changeSession(session);
    },
    [changeSession],
  );

  const handleSessionMinutesInputBlur = useCallback(
    ({ session, seconds }: BlurPayload) => {
      const focusSeconds = focusMinutes * 60;
      const shortBreakSeconds = shortBreakMinutes * 60;
      const longBreakSeconds = longBreakMinutes * 60;

      const payload: Partial<UserSetting> = {
        [DBSettingColumns.focusDuration]: session === SessionType.focus ? seconds : focusSeconds,
        [DBSettingColumns.shortBreakDuration]: session === SessionType.shortBreak ? seconds : shortBreakSeconds,
        [DBSettingColumns.longBreakDuration]: session === SessionType.longBreak ? seconds : longBreakSeconds,
      };

      if (user) {
        updateSettingFromDB(user, payload);
        updateSettingToStore(payload);
      } else {
        saveNewSettingInLocal(payload);
        updateSettingToStore(payload);
      }
    },
    [user, focusMinutes, shortBreakMinutes, longBreakMinutes, updateSettingToStore],
  );

  return (
    <TimerControllerWrapper>
      <SessionButtonsWrapper>
        <SessionButton
          initialValue={focusMinutes.toString()}
          focused={sessionType === SessionType.focus}
          session={SessionType.focus}
          ticking={ticking}
          mute={focusMute}
          onClick={handleClickButton}
          onBlur={handleSessionMinutesInputBlur}
        ></SessionButton>
        <SessionButton
          initialValue={shortBreakMinutes.toString()}
          focused={sessionType === SessionType.shortBreak}
          session={SessionType.shortBreak}
          ticking={ticking}
          mute={shortBreakMute}
          onClick={handleClickButton}
          onBlur={handleSessionMinutesInputBlur}
        ></SessionButton>
        <SessionButton
          initialValue={longBreakMinutes.toString()}
          focused={sessionType === SessionType.longBreak}
          session={SessionType.longBreak}
          ticking={ticking}
          mute={longBreakMute}
          onClick={handleClickButton}
          onBlur={handleSessionMinutesInputBlur}
        ></SessionButton>
      </SessionButtonsWrapper>
      <StyledTimerControlButton></StyledTimerControlButton>
    </TimerControllerWrapper>
  );
};

const TimerControllerWrapper = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 12px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 0 20px;
  }
`;

const SessionButtonsWrapper = styled('div')`
  display: flex;
  align-items: center;
  column-gap: 4px;
  flex-wrap: wrap;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 100%;
    row-gap: 4px;
  }
`;

const StyledTimerControlButton = styled(TimerControlButton)`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 100%;
    button {
      width: 100%;
      margin-top: 12px;
    }
  }
`;
