import { useAnimate } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import InlineSVG from 'react-inlinesvg';

interface SinglePathSVGAnimationProps {
  src: string;
  width: number;
  height: number;
  duration?: number;
  delay?: number;
  active?: boolean;
}

export const SinglePathSVGAnimation = ({
  src,
  width,
  height,
  duration = 1,
  delay = 0,
  active = true,
}: SinglePathSVGAnimationProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [scope, animate] = useAnimate();

  const preProcessor = useCallback<InlineSVG.PreProcessorCallback>((svg) => {
    const dom = new DOMParser().parseFromString(svg, 'image/svg+xml');
    const paths = dom.querySelectorAll('path');
    const path = paths[0];
    path.setAttribute('strokeDasharray', '1000');
    path.setAttribute('strokeDashoffset', '1000');

    return dom.documentElement.outerHTML;
  }, []);

  const handleLoaded = useCallback(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded && active) {
      animate('svg path', { strokeDashoffset: 0 }, { duration, delay });
    }
  }, [isLoaded, active, duration, delay, animate]);

  return (
    <div ref={scope} className='SinglePathSVGAnimation'>
      <InlineSVG src={src} width={width} height={height} preProcessor={preProcessor} onLoad={handleLoaded}></InlineSVG>
    </div>
  );
};
