import { styled } from '@mui/material';
import InlineSVG from 'react-inlinesvg';
import { LogoFocuskeeperSvg } from '../../assets';
import { CSSProperties } from 'react';

interface AppLogoImageProps {
  className?: string;
  style?: CSSProperties;
}

export const AppLogoImage = ({ className, style }: AppLogoImageProps) => {
  return (
    <Wrapper className={className} style={style}>
      <InlineSVG width={40} height={40} src={LogoFocuskeeperSvg}></InlineSVG>
    </Wrapper>
  );
};

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: #fff;
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f0f0f0;
`;
