import { Grid, styled, Typography } from '@mui/material';
import { CSSProperties, PropsWithChildren, useRef } from 'react';
import { TotalScore } from '../TotalScore';
import { ReviewCardList } from '../ReviewCardList';
import { useInView, motion } from 'framer-motion';
import { TransitionVariants } from '../../constants/Transition';

export const Reviews = () => {
  const rootEl = useRef<HTMLDivElement>(null);
  const isInView = useInView(rootEl, { once: true });

  return (
    <Background ref={rootEl}>
      <Wrapper>
        <TitleAndScoreWrapper>
          <Grid container alignItems={'center'} justifyContent={'center'}>
            <Grid item xs={12} md={8}>
              <motion.div
                variants={TransitionVariants}
                initial={'fadeOut'}
                animate={isInView && 'fadeIn'}
                transition={{
                  duration: 0.4,
                }}
              >
                <Title>Loved by so many people around the world.</Title>
              </motion.div>
              <TotalScoreWrapper
                variants={TransitionVariants}
                initial={'fadeOut'}
                animate={isInView && 'fadeIn'}
                transition={{
                  duration: 0.4,
                  delay: 0.2,
                }}
              >
                <TotalScore inView={isInView}></TotalScore>
              </TotalScoreWrapper>
            </Grid>
          </Grid>
        </TitleAndScoreWrapper>
        <ReviewCardWrapper
          variants={TransitionVariants}
          initial={'fadeOut'}
          animate={isInView && 'fadeIn'}
          transition={{
            duration: 0.4,
            delay: 0.4,
          }}
        >
          <ReviewCardList></ReviewCardList>
        </ReviewCardWrapper>
      </Wrapper>
    </Background>
  );
};

const Background = styled('section')`
  background-color: var(--current-session-color);
  transition: var(--background-transition-duration) background-color;
`;

const Wrapper = styled('article')`
  padding: 80px 0 120px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 40px 0 100px;
  }
`;

const TitleAndScoreWrapper = styled('div')`
  max-width: 1366px;
  margin: auto;
  padding: 0 67px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 0 20px;
  }
`;

interface TitleProps extends PropsWithChildren {
  className?: string;
  style?: CSSProperties;
}

const Title = styled(({ className, style, children }: TitleProps) => {
  return (
    <Typography component={'h3'} className={className} style={style}>
      {children}
    </Typography>
  );
})`
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 52.8px */
  color: #fff;
  text-align: center;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 46.8px */
  }
`;

const TotalScoreWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
`;

const ReviewCardWrapper = styled(motion.div)`
  margin-top: 48px;
`;
