import { CSSProperties } from 'react';
import { clsx } from 'clsx';
import Marquee from 'react-fast-marquee';
import {
  LogoDigitalJournalImage,
  LogoFoxImage,
  LogoLifewireImage,
  LogoMakeUseOfImage,
  LogoMarketWatchImage,
  LogoNBCNewsImage,
  LogoNCNImage,
  LogoPracticalECommerceImage,
  LogoSlashGearImage,
  LogoUSATodayImage,
} from '../../assets';
import { styled } from '@mui/material';

interface SponsorsProps {
  className?: string;
  style?: CSSProperties;
}

const partnersLogos = [
  LogoFoxImage,
  LogoNBCNewsImage,
  LogoNCNImage,
  LogoLifewireImage,
  LogoUSATodayImage,
  LogoMakeUseOfImage,
  LogoSlashGearImage,
  LogoMarketWatchImage,
  LogoDigitalJournalImage,
  LogoPracticalECommerceImage,
];

export const PartnersLogoMarquee = styled(({ className, style }: SponsorsProps) => {
  return (
    <div className={clsx('Sponsors', className)} style={style}>
      <Marquee speed={40}>
        <PartnerList>
          {partnersLogos.map((image, key) => (
            <Partner key={key} sponsorImage={image}></Partner>
          ))}
        </PartnerList>
      </Marquee>
    </div>
  );
})`
  width: 100%;
  overflow: hidden;
  position: relative;
  .rfm-marquee {
    min-width: 0;
  }
`;

const PartnerList = styled('ul')`
  display: flex;
  align-items: center;
`;

interface SponsorProps {
  className?: string;
  style?: CSSProperties;
  sponsorImage: string;
}

const Partner = styled(({ className, style, sponsorImage }: SponsorProps) => {
  return (
    <li className={clsx(className)} style={style}>
      <img src={sponsorImage} alt='Sponsor' height={40}></img>
    </li>
  );
})`
  margin-right: 96px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    margin-right: 72px;
  }
`;
