import { Button, Grid, Typography, useTheme, styled, useMediaQuery } from '@mui/material';
import { AppDownloadQRCode } from '../AppDownloadQRCode';
import { ThumbnailAppDownloadMImage } from '../../assets';
import { AppLogoImage } from './AppLogoImage';
import { CSSProperties, useRef } from 'react';
import { useInView, motion } from 'framer-motion';
import { TransitionVariants } from '../../constants/Transition';
import { ResponsiveVisibility } from '../ResponsiveVisibility';

export const AppDownload = () => {
  const rootEl = useRef<HTMLDivElement>(null);
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('lg'));
  const isInView = useInView(rootEl, { once: true, amount: 0.2 });

  return (
    <Background ref={rootEl}>
      <Wrapper>
        <Grid container alignItems={'flex-end'}>
          <Grid item lg={6} xs={12} order={isMobile ? 2 : 0}>
            <motion.div
              variants={TransitionVariants}
              initial={'fadeOut'}
              animate={isInView && 'fadeIn'}
              transition={{
                delay: isMobile ? 0.8 : 0,
              }}
            >
              <AppMobileImage src={ThumbnailAppDownloadMImage} alt='App Download' />
            </motion.div>
          </Grid>
          <Grid item lg={1} xs={0} order={1}></Grid>
          <Grid item lg={5} xs={12} order={isMobile ? 0 : 2}>
            <DownloadGuideSection>
              <motion.div
                variants={TransitionVariants}
                initial={'fadeOut'}
                animate={isInView && 'fadeIn'}
                transition={{ delay: isMobile ? 0 : 0.2 }}
              >
                <StyledAppLogoImage></StyledAppLogoImage>
              </motion.div>
              <motion.div
                variants={TransitionVariants}
                initial={'fadeOut'}
                animate={isInView && 'fadeIn'}
                transition={{ delay: isMobile ? 0.2 : 0.4 }}
              >
                <Title>Find Focus Keeper on mobile</Title>
              </motion.div>
              <motion.div
                variants={TransitionVariants}
                initial={'fadeOut'}
                animate={isInView && 'fadeIn'}
                transition={{ delay: isMobile ? 0.4 : 0.6 }}
              >
                <Content>Focus Keeper is available on both iOS and Android devices</Content>
              </motion.div>
              <motion.div
                variants={TransitionVariants}
                initial={'fadeOut'}
                animate={isInView && 'fadeIn'}
                transition={{ delay: isMobile ? 0.6 : 0.8 }}
              >
                <ResponsiveVisibility
                  type='up'
                  responsive={{
                    lg: <StyledAppDownloadQRCode></StyledAppDownloadQRCode>,
                  }}
                />
                <ResponsiveVisibility
                  type='down'
                  responsive={{
                    lg: (
                      <a target='_blank' href='https://306pd.app.link/focuskeeper' rel='noreferrer'>
                        <MobileDownloadButton />
                      </a>
                    ),
                  }}
                />
              </motion.div>
            </DownloadGuideSection>
          </Grid>
        </Grid>
      </Wrapper>
    </Background>
  );
};

const Background = styled('section')`
  width: 100%;
  background-color: var(--background-color);
`;

const Wrapper = styled('article')`
  padding: 0 67px;
  max-width: 1366px;
  margin: auto;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 0 20px;
  }
`;

const AppMobileImage = styled('img')`
  width: 100%;
  display: block;
`;

const DownloadGuideSection = styled('div')`
  padding: 80px 0;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 80px 0 40px;
  }
`;

const StyledAppLogoImage = styled(AppLogoImage)`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    margin: auto;
  }
`;

const Title = styled(Typography)`
  color: #080808;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 67.2px */
  margin-top: 24px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 52.8px */
    text-align: center;
  }
`;

const Content = styled(Typography)`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  color: #0000008a;
  margin-top: 12px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    text-align: center;
  }
`;

const StyledAppDownloadQRCode = styled(AppDownloadQRCode)`
  margin-top: 42px;
`;

interface MobileDownloadButtonProps {
  className?: string;
  style?: CSSProperties;
}

const MobileDownloadButton = styled(({ className, style }: MobileDownloadButtonProps) => {
  return (
    <Button className={className} style={style} variant='contained'>
      App Download
    </Button>
  );
})`
  display: block;
  width: 192px;
  height: 48px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
  margin: 40px auto 0;
`;
