import { Typography, styled } from '@mui/material';
import { CSSProperties, PropsWithChildren, useCallback } from 'react';
import { SessionType } from '../../types';
import { TimerIcon } from '../TimerIcon/TimerIcon';
import clsx from 'clsx';
import { SessionTimeInput, SessionTimeInputBlurArguments } from './SessionTimeInput';
import { MuteButton } from './MuteButton';
import { ResponsiveVisibility } from '../ResponsiveVisibility';

interface SessionButtonProps {
  className?: string;
  style?: CSSProperties;
  session: SessionType;
  focused: boolean;
  initialValue: string;
  ticking: boolean;
  mute: boolean;
  onClick?: (session: SessionType) => void;
  onBlur?: (args: SessionTimeInputBlurArguments) => void;
  onMute?: (flag: boolean) => void;
}

export const SessionButton = styled(
  ({ className, initialValue, style, session, focused, ticking, mute, onClick, onBlur }: SessionButtonProps) => {
    let isDisabled = false;
    if (ticking) isDisabled = true;
    if (focused) isDisabled = true;

    const handleClick = useCallback(() => {
      if (isDisabled) return;

      onClick?.(session);
    }, [session, isDisabled, onClick]);

    return (
      <div className={clsx(className, { disabled: isDisabled })} style={style} role='button' onClick={handleClick}>
        <TitleRow ticking={ticking} session={session} focused={focused}></TitleRow>
        <ContentRow
          ticking={ticking}
          initialValue={initialValue}
          focused={focused}
          session={session}
          onBlur={onBlur}
        ></ContentRow>
      </div>
    );
  },
)`
  display: flex;
  flex-direction: column;
  ${({ session }) => session === SessionType.focus && '--theme-color: #FF5C5C;'}
  ${({ session }) => session === SessionType.shortBreak && '--theme-color: #7684FF;'}
  ${({ session }) => session === SessionType.longBreak && '--theme-color: #3D3D3D;'}
  width: 180px;
  background-color: white;
  padding: 12px 16px;
  border-radius: 8px;
  border: 2px solid #fff3e2;
  transition: 0.3s background-color;
  overflow: hidden;
  cursor: pointer;
  ${({ focused, ticking }) => !focused && ticking && 'opacity: .4;'}
  ${({ focused }) => focused && 'background-color: var(--theme-color);'}
  ${({ focused }) => focused && 'color: #FFF3E2;'}
  ${({ focused, ticking }) => !focused && ticking && 'opacity: 0.4;'}
  &.disabled {
    cursor: default;
  }
  ${({ theme }) => theme.breakpoints.down('lg')} {
    flex-direction: row;
    width: 100%;
  }
`;

interface TitleRowProps {
  className?: string;
  style?: CSSProperties;
  session: SessionType;
  ticking: boolean;
  focused?: boolean;
}

/**
 * 타이머의 유형을 표시하는 윗 내용줄입니다.
 */
const TitleRow = styled(({ className, style, session, ticking, focused }: TitleRowProps) => {
  let label = 'Focus';
  if (session === SessionType.shortBreak) label = 'Short Break';
  if (session === SessionType.longBreak) label = 'Long Break';

  return (
    <div className={className} style={style}>
      <ResponsiveVisibility
        type='down'
        responsive={{
          lg: <StyledIcon focused={focused} session={session}></StyledIcon>,
        }}
      ></ResponsiveVisibility>
      <Label>{label}</Label>

      <ResponsiveVisibility
        type={'up'}
        responsive={{
          lg: focused && <MuteButton></MuteButton>,
        }}
      ></ResponsiveVisibility>
    </div>
  );
})`
  color: var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  transition: 0.3s color;
  ${({ focused }) => focused && 'color: white;'}
  ${({ theme }) => theme.breakpoints.down('lg')} {
    flex: auto;
    justify-content: flex-start;
    font-size: 16px;
  }
`;

const Label = styled('span')`
  display: inline-block;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    margin-left: 8px;
  }
`;

/**
 * 타이머 내용을 기입하는 아래 내용줄입니다.
 */
interface ContentRowProps {
  className?: string;
  style?: CSSProperties;
  initialValue: string;
  session: SessionType;
  ticking?: boolean;
  focused?: boolean;
  onBlur?: (args: SessionTimeInputBlurArguments) => void;
}

const ContentRow = styled(({ focused, className, style, ticking, session, initialValue, onBlur }: ContentRowProps) => {
  return (
    <div className={className} style={style}>
      <ResponsiveVisibility
        type={'up'}
        responsive={{
          lg: <StyledIcon focused={focused} session={session}></StyledIcon>,
        }}
      ></ResponsiveVisibility>
      <StyledSessionTimeInput
        ticking={ticking}
        focused={focused}
        initialValue={initialValue}
        session={session}
        onBlur={onBlur}
      ></StyledSessionTimeInput>
      <Unit focused={focused}>min</Unit>
      <ResponsiveVisibility
        type={'down'}
        responsive={{
          lg: <MobileMuteButtonWrapper>{focused && <MuteButton></MuteButton>}</MobileMuteButtonWrapper>,
        }}
      ></ResponsiveVisibility>
    </div>
  );
})`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  column-gap: 8px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: auto;
  }
`;

const StyledIcon = styled(TimerIcon)`
  min-width: 24px;
  flex: 0 1 auto;
`;

const StyledSessionTimeInput = styled(SessionTimeInput)`
  width: 64px;
  text-align: right;
`;

/**
 * 입력 단위를 표현합니다.
 */
interface UnitProps extends PropsWithChildren {
  className?: string;
  style?: CSSProperties;
  focused?: boolean;
}

const Unit = styled(({ className, style, children }: UnitProps) => {
  return (
    <Typography className={className} style={style}>
      {children}
    </Typography>
  );
})`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  color: #a1a1a1;
  ${({ focused }) => focused && 'color: white;'}
  flex: 0 1 auto;
`;

const MobileMuteButtonWrapper = styled('div')`
  width: 20px;
`;
