import { Grid, Typography, styled } from '@mui/material';
import { CSSProperties, PropsWithChildren, useRef } from 'react';
import { clsx } from 'clsx';
import { Lottie01 } from '../../assets/lotties';
import { motion, useInView, Variants } from 'framer-motion';
import { LottieImage } from '../LottieImage';
import { TransitionVariants } from '../../constants/Transition';

interface SectionProps {
  className?: string;
  style?: CSSProperties;
}

export const Section = styled(({ className, style }: SectionProps) => {
  const articleEl = useRef<HTMLElement>(null);
  const isInView = useInView(articleEl, { amount: 0.4, once: true });

  return (
    <article ref={articleEl} className={clsx(className, 'Section')} style={style}>
      <Grid container justifyContent={'center'}>
        <Grid item lg={12} md={8} xs={4}>
          <LottieWrapper>
            <motion.div
              variants={TransitionVariants}
              initial={'fadeOut'}
              animate={isInView && 'fadeIn'}
              transition={{ duration: 0.4 }}
            >
              <LottieImage options={{ animationData: Lottie01 }} width={200}></LottieImage>
            </motion.div>
          </LottieWrapper>
        </Grid>
        <Grid item lg={12} md={8} xs={4}>
          <motion.div
            variants={TransitionVariants}
            initial={'fadeOut'}
            animate={isInView && 'fadeIn'}
            transition={{ duration: 0.4, delay: 0.5 }}
          >
            <Pharagraph>
              The Pomodoro Technique is a time management method that can significantly boost your focus and
              productivity.
            </Pharagraph>
          </motion.div>
        </Grid>
      </Grid>
    </article>
  );
})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const LottieWrapper = styled('div')`
  width: 100%;
  margin-bottom: 24px;
`;

interface PharagraphProps extends PropsWithChildren {
  className?: string;
  style?: CSSProperties;
}

const Pharagraph = styled(({ className, style, children }: PharagraphProps) => {
  return (
    <Typography component={'p'} className={className} style={style}>
      {children}
    </Typography>
  );
})`
  color: #1e1e1e;
  text-align: center;
  font-size: 56px;
  line-height: 120%;
  font-weight: 600;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 46.8px */
  }
`;
