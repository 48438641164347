import Lottie, { LottieProps } from 'react-lottie';

interface LottieImageProps extends LottieProps {
  className?: string;
}

export const LottieImage = ({ className, options, isClickToPauseDisabled = true, ...others }: LottieImageProps) => {
  return (
    <Lottie
      isClickToPauseDisabled={isClickToPauseDisabled}
      {...others}
      options={{ ...options, rendererSettings: { className } }}
    ></Lottie>
  );
};
