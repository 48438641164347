import { styled } from '@mui/material';
import { Section } from './Section';

export const ServiceIntro = () => {
  return (
    <Wrapper>
      <Section></Section>
    </Wrapper>
  );
};

const Wrapper = styled('section')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 160px 67px 40px;
  max-width: 1366px;
  margin: auto;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 80px;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 80px 20px;
  }
`;
