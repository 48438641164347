import { Howl } from 'howler';
import type { LocalSound } from '../../types';
import { AudioType } from '../../types';

const withBaseUrl = (type: AudioType, name: string, fileName: string) => ({
  name,
  src: `${process.env.PUBLIC_URL}/sounds/${type}/${fileName}`,
});

export const alarmNames = {
  'Wind Up': 'Wind Up.mp3',
  Whistle: 'Whistle.mp3',
  Waterdrop: 'Waterdrop.mp3',
  'Three Ring Chime': 'Three Ring Chime.mp3',
  Sweet: 'Sweet.mp3',
  Steam: 'Steam.mp3',
  Slideout2: 'Slideout2.mp3',
  Slideout1: 'Slideout1.mp3',
  Slash: 'Slash.mp3',
  News: 'News.mp3',
  None: 'None.mp3',
  Kitten: 'Kitten.mp3',
  Kitchen: 'Kitchen.mp3',
  Jingle: 'Jingle.mp3',
  Ding: 'Ding.mp3',
  Desk: 'Desk.mp3',
  Cymbals: 'Cymbals.mp3',
  Chimes: 'Chimes.mp3',
  Bronze: 'Bronze.mp3',
  Bicycle: 'Bicycle.mp3',
  Bell: 'Bell.mp3',
  Beep: 'Beep.mp3',
};
export const tickingNames = {
  'Wall Clock Ticking': 'Wall Clock Ticking.mp3',
  'Ticking Slow': 'Ticking Slow.mp3',
  'Ticking Fast': 'Ticking Fast.mp3',
  Ticking: 'Ticking.mp3',
  'Tick Tock 2': 'Tick Tock 2.mp3',
  'Tick Tock 1': 'Tick Tock 1.mp3',
  Steam: 'Steam.mp3',
  Rain: 'Rain.mp3',
  'Rain Light': 'Rain Light.mp3',
  'Fiji Beach': 'Fiji Beach.mp3',
  'Fast Ticking': 'Fast Ticking.mp3',
  Dripping: 'Dripping.mp3',
  'Cafe Noise 2': 'Cafe Noise 2.mp3',
  'Cafe Noise': 'Cafe Noise.mp3',
};

export const alarmSounds: LocalSound[] = Object.entries(alarmNames).map(([name, fileName]) => {
  return withBaseUrl(AudioType.alarm, name, fileName);
});
export const tickingSounds: LocalSound[] = Object.entries(tickingNames).map(([name, fileName]) => {
  return withBaseUrl(AudioType.ticking, name, fileName);
});

export const NoneSound = withBaseUrl(AudioType.alarm, 'None', 'None.mp3');
export const noneHowl = new Howl({ src: NoneSound.src });
