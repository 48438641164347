import { styled } from '@mui/material';
import { CSSProperties, useCallback } from 'react';
import InlineSVG from 'react-inlinesvg';
import { TomatoFillIcon, TomatoLineIcon } from '../../assets';

interface PomodoroCounterProps {
  className?: string;
  style?: CSSProperties;
  value: number;
  total: number;
}

export const PomodoroCounter = ({ className, style, value = 1, total = 5 }: PomodoroCounterProps) => {
  const changeTomatoStyle = useCallback((svg: string, fill: string) => {
    const tree = new DOMParser().parseFromString(svg, 'image/svg+xml');
    const paths = tree.getElementsByTagName('path');

    for (let i = 0; i < paths.length; i += 1) {
      const path = paths.item(i);
      path?.setAttribute('fill', fill);
      path?.setAttribute('fill-opacity', '1');
    }

    return tree.documentElement.outerHTML;
  }, []);

  const activeTomatoPreprocessor = useCallback((svg: string) => changeTomatoStyle(svg, '#FFF3E2'), [changeTomatoStyle]);
  const inactiveTomatoPreprocessor = useCallback((svg: string) => changeTomatoStyle(svg, '#FFF3E2'), []);

  return (
    <PomodoroWrapper className={className} style={style}>
      {Array(total)
        .fill('')
        .map((_, index) => {
          if (index < value) {
            return <InlineSVG key={index} src={TomatoFillIcon} preProcessor={activeTomatoPreprocessor}></InlineSVG>;
          } else {
            return <InlineSVG key={index} src={TomatoLineIcon} preProcessor={inactiveTomatoPreprocessor}></InlineSVG>;
          }
        })}
    </PomodoroWrapper>
  );
};

const PomodoroWrapper = styled('div')`
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 8px;
  border-radius: 99px;
  background: #0000000f;
`;
